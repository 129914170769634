import axios from 'axios';
import { authHeader, handle_response } from './helper';

export const nodes_service = {
  get_nodes,
  add_node,
  delete_node,
  get_node,
  update_node,
};

const URL = process.env.VUE_APP_GENERAL_API_ENDPOINT;

function get_nodes(options) {
  const request_options = {
    method: 'get',
    url: (`${URL}nodes`),
    // headers: authHeader(),
    // data: { },
    params: options,

  };

  return axios(request_options)
    .then((value) => handle_response(value));
}

function get_node(id) {
  const request_options = {
    method: 'get',
    url: (`${URL}nodes/${id}`),
  };

  return axios(request_options)
    .then((value) => handle_response(value));
}

function add_node(data) {
  console.log(data);
  const request_options = {
    method: 'post',
    url: (`${URL}nodes`),
    headers: authHeader(),
    data,
  };

  return axios(request_options)
    .then((value) => handle_response(value));
}

/**
 * @param data
 * Example:
 * { "node_id": "N39", "params":{"LV":0,"D":"2"}, "config": {"type":"node", "version": "V-XB"}}
 * @note: params and config property are optional.
 * @returns {Promise<AxiosResponse<any>>}
 */
function update_node(data) {
  const request_options = {
    method: 'put',
    url: (`${URL}nodes`),
    headers: authHeader(),
    data,
  };

  return axios(request_options)
    .then((value) => handle_response(value));
}

function delete_node(node_id) {
  const request_options = {
    method: 'delete',
    url: (`${URL}nodes/${node_id}`),
    headers: authHeader(),
  };

  return axios(request_options)
    .then((value) => handle_response(value));
}
