import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './plugins/i18n';
import store from './store';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import { auth } from './plugins/firebase';
import 'firebase/auth';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import VueApexCharts from 'vue-apexcharts';
import VueMeta from 'vue-meta';

Vue.config.productionTip = false;

Vue.component('apexchart', VueApexCharts);
Vue.use(VueApexCharts);
Vue.use(VueMeta);

let app;

// store.dispatch('authentication/logout', false, { root: true })

auth.onIdTokenChanged(async() => {

  try {

    if (!app) {
      app = new Vue({
        i18n,
        store,
        router,
        vuetify,
        render: (h) => h(App),
      }).$mount('#app');
    }
    
    if(auth.currentUser){
      const token = await auth.currentUser.getIdToken()
      store.dispatch('authentication/loginWithFirebaseIdToken', { token }, { root: true })
      store.commit('authentication/SET_PROJECT', null, { root: true })
      router.push(`${localStorage.getItem('currentRoute')}`).catch(()=>{})
      store.dispatch('app/set_drawer_visible', true, { root: true })
    } else {
      store.dispatch('authentication/logout', false, { root: true })
    }

    store.dispatch('app/set_app_ready_status', true);
    
  } catch (error) {
    console.log(error);
    store.dispatch('authentication/logout', false, { root: true })
  }

  store.dispatch('app/set_app_ready_status', true);

});
