import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '../components/languages/en.json';
import es from '../components/languages/es.json';

Vue.use(VueI18n);

const languages = {
  en,
  es,
};

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'es',
  messages: languages,
});

// export default i18n;
