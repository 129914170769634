import axios from 'axios';
import { authHeader, handle_response } from './helper';

export const instructions_service = {
  get_instructions,
  create_instruction,
};

const URL = process.env.VUE_APP_GENERAL_API_ENDPOINT;

function get_instructions(options) {
  const request_options = {
    method: 'get',
    url: (`${URL}instructions`),
    headers: authHeader(),
    params: options,
  };

  return axios(request_options)
    .then((value) => handle_response(value));
}

function create_instruction(message, options = null) {
  const request_options = {
    method: 'post',
    url: (`${URL}instructions`),
    headers: authHeader(),
    data: message,
    params: options,
  };

  return axios(request_options)
    .then((value) => handle_response(value));
}
