import { auth, db } from '../../plugins/firebase';
import { User } from '../../api';
import apiMeterClient2 from '@/api/apiMeterClient2';

export const authentication = {
  namespaced: true,
  state: { 
    status: { 
      loggedIn: false, 
      loggingIn: false, 
    }, 
    project: null,
    user: undefined 
  },
  getters: {
    logged_in(state) {
      return state.status.loggedIn;
    },
    logging_in(state) {
      return state.status.loggingIn;
    },
    get_user(state) {
      return state.user;
    },
    get_user_id(state) {
      return state.user ? (state.user.user_id ? state.user.user_id : state.user.email) : null;
    },
    get_token(state) {
      return state.user.token;
    },
    get_status(state) { 
      return state.status
    }
  },
  actions: {
    async login({ commit, dispatch }, payload) {
      payload.loginType = 'email'
      const emailLogin = await User.login(payload)
      if(emailLogin.hasOwnProperty('message')) throw new Error(emailLogin.message)
      commit('LOGGING_IN', true);

      if(emailLogin.data.projects.length === 0) {
        commit('LOGGING_IN', false);
        throw new Error('No projects assigned to this user')
      }

      if(emailLogin.data.projects.length > 1){
        sessionStorage.setItem('accountInfo', JSON.stringify(emailLogin.data))  
        localStorage.setItem('projectsAssigned', JSON.stringify(emailLogin.data.projects))
        commit('LOGGING_IN', false);
        throw new Error('Multiple projects')
      }

      dispatch('loginWithCustomToken', emailLogin.data)
    },
    async loginWithFirebaseIdToken({ dispatch, commit }, payload){
      try {
        payload.loginType = 'token'
        const tokenLogin = await User.login(payload)
        const { user, apiClientToken, projects } = tokenLogin.data
        dispatch('setProjectForAPI', projects)
        sessionStorage.setItem('token', apiClientToken);
        const { name, email, firebaseUid: uid } = user
        commit('LOGIN_SUCCESS', { name, email, uid, apiClientToken, origin: 'token' });
      } catch (error) {
        dispatch('logout')
      }
      
    },
    async loginWithCustomToken({ commit, dispatch }, accountInfo){
      const { apiClientToken, projects, user, firebaseCustomToken } = accountInfo
      const {name, email, firebaseUid: uid} = user
      await auth.signInWithCustomToken(firebaseCustomToken)
        .then(async() => { 
          sessionStorage.setItem('token', apiClientToken);
          dispatch('setProjectForAPI', projects)
          dispatch('nodes/retrieve_data', null, { root: true })
          commit('LOGIN_SUCCESS', { name, email, uid, apiClientToken, origin: 'email' });
          commit('LOGGING_IN', false);
          dispatch('app/set_app_ready_status', true, { root: true });
        })
        .catch((error) => { 
          console.log(error);
          const { message } = error
          commit('LOGIN_FAILURE', message);
          commit('LOGGING_IN', false);
          dispatch('alert/error', message, { root: true }); 
          dispatch('logout')
        })
    },
    setProjectForAPI({ commit }, projects){ 
      const multiProjectAccount = JSON.parse(sessionStorage.getItem('accountInfo'))
      const selectedProject = multiProjectAccount ? multiProjectAccount.projects[0].name_eui : projects[0].name_eui
      localStorage.setItem('project', selectedProject);
      commit('SET_PROJECT', selectedProject)
    },
    async set_user({ commit, dispatch }) {
      const { currentUser } = auth;
      if (!currentUser) return;
      const req = await db.doc(`usersGreenweb/${currentUser.uid}`).get();
      const user = req.data();
      const version = '1.7.6';
      if (!user) return;
      dispatch('alert/clear', null, { root: true });
    },
    logout({ commit, dispatch }) {
      localStorage.clear();
      sessionStorage.clear();
      auth.signOut().then(() => {
        dispatch('messages/clean_messages', null, { root: true });
        dispatch('app/set_drawer_visible', false, { root: true });
        commit('LOGOUT');
      });
    },
  },
  mutations: {
    LOGIN_SUCCESS(state, payload) {
      state.status.loggedIn = true;
      localStorage.setItem('user', JSON.stringify(payload));
      state.user = JSON.parse(localStorage.getItem('user'));
    },
    LOGIN_FAILURE(state, payload) {
      state.user = undefined;
      state.status.error = payload ?? {}
    },
    LOGOUT(state) {
      state.status.loggedIn = false;
      state.user = undefined;
    },
    LOGGING_IN(state, payload) {
      state.status.loggingIn = payload;
    },
    SET_PROJECT(state){
      state.project = localStorage.getItem('project');
    }
  },
};
