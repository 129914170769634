import { greenetworkBaseAPI } from './greenetworkAPI';

const END_POINT = '/users';

const login = async(logInInfo) => {
    try {
        const { loginType, email, password, token } = logInInfo;
        const requestBody = loginType === 'email' ? { email, password } : { token };
        const response = await greenetworkBaseAPI.post(`${END_POINT}/login/${loginType}`, requestBody)
        return response    
    } catch (error) {
        return { status: 'ERROR', message: error.message }   
    }
}

const refresh = async(token) => {
    try {
        const payload = { firebaseToken: token }
        console.log(payload);
        const response = await greenetworkBaseAPI.post(`${END_POINT}/refresh-token`, payload)
        console.log(response);
        return response    
    } catch (error) {
        console.log(error);
        return { status: 'ERROR', message: error.message }
    }
    
}

const User = { login, refresh };

export default User;