import { apiWithProject } from "./greenetworkAPI";

const END_POINT = "/devices/groups/meters";

const createGroup = (data) => apiWithProject.post(END_POINT, { ...data });

const getGroups = () => apiWithProject.get(END_POINT);

const getGroupById = (id) =>
  apiWithProject.get(`${END_POINT}/${id}`);

const updateGroup = (data, id) =>
  apiWithProject.put(`${END_POINT}/${id}`, { ...data });

const deleteGroup = (id) =>
  apiWithProject.delete(`${END_POINT}/${id}`);

const MeterGreen = {
  createGroup,
  getGroups,
  getGroupById,
  updateGroup,
  deleteGroup,
};

export default MeterGreen;
