<template>
  <div>
    <v-dialog v-model="open" persistent width="500px">
      <v-card>
        <v-card-title>
          <v-row justify="space-between" no-gutters>
            <v-col> Gateways registered </v-col>
            <v-col cols="auto">
              <v-btn icon @click="closeDialog()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row v-if="gateways.length > 0">
            <v-col cols="12" class="pt-6 pb-4">
              <v-row
                v-for="gateway in gateways"
                :key="gateway.id"
                align="center"
              >
                <v-col class="py-1">
                  {{ gateway.id }}
                </v-col>
                <v-col cols="auto" class="py-1">
                  <v-chip
                    @click="assignGateway(gateway)"
                    style="width: 130px"
                    class="justify-center white--text"
                    :disabled="gateway.main"
                    :color="gateway.main ? 'primary' : 'secondary'"
                  >
                    <span>
                      {{ gateway.main ? "Assigned" : "Assign as main" }}
                    </span>
                  </v-chip>
                  <v-tooltip right :disabled="!gateway.main" max-width="200" >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="d-inline-block">
                        <v-btn
                          icon
                          @click="deleteGateway(gateway)"
                          :disabled="gateway.main"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <div class="text-center">The main gateway cannot be deleted</div>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-else justify="center">
            <v-col class="grey--text text-center">
              It seems you don't have any gateway registered
              <br />
              Click on "Add Gateway" button to add a new gateway
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="auto" class="ml-auto">
              <v-btn color="primary" @click="addGatewayDialog = true">
                Add Gateway
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <AddGateway :open="addGatewayDialog" @close="addGatewayDialog = false" />
    <AssignGateway
      :dialog="assignGatewayDialog"
      :gateway="selectedGateway"
      @close="assignGatewayDialog = false"
    />
    <DeleteGateway
      :dialog="deleteGatewayDialog"
      :gateway="selectedGateway"
      @close="deleteGatewayDialog = false"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import AddGateway from "./AddGateway.vue";
import AssignGateway from "./AssignGateway.vue";
import DeleteGateway from "./DeleteGateway.vue";

export default {
  name: "Gateways",
  components: {
    AddGateway,
    AssignGateway,
    DeleteGateway,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      gateways: (state) => state.gateways.gateways,
      user: (state) => state.authentication.user,
    }),
  },
  data() {
    return {
      addGatewayDialog: false,
      assignGatewayDialog: false,
      deleteGatewayDialog: false,
      selectedGateway: {},
    };
  },
  methods: {
    ...mapActions({
      retrieve_data: "nodes/retrieve_data",
    }),
    async refreshData() {
      await this.retrieve_data();
    },
    deleteGateway(gateway) {
      this.deleteGatewayDialog = true;
      this.selectedGateway = gateway;
    },
    assignGateway(gateway) {
      this.assignGatewayDialog = true;
      this.selectedGateway = gateway;
    },
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
