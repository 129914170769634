import apiMeterClient2 from './apiMeterClient2';

const END_POINT = '/readings';

const setOptionsRequest = (options) => {
  let optionString = JSON.stringify(options || '').replace(/"|{|}/g, '').replace(/:/g, '=').replace(/,/g, '&');
  if (optionString.length > 0) optionString = `&${optionString}`;
  return optionString;
};

const last = (meters) => apiMeterClient2.get(`${END_POINT}/last?meters=${meters.toString()}`);

const daySummary = (meter, time) => apiMeterClient2.get(`${END_POINT}/summary/day/${meter}?start=${time.start}&end=${time.end}`);

const byMeter = (meter, options) => apiMeterClient2.get(`${END_POINT}/meter/${meter.id}?key=${meter.key}${setOptionsRequest(options)}`);

const hourSummary = (meter, hour) => apiMeterClient2.get(`${END_POINT}/summary/hour/${meter}?start=${hour}`); // not working

const Readings = {
  last, daySummary, byMeter, hourSummary,
};

export default Readings;
