import { apiWithProject } from './greenetworkAPI';

const END_POINT = '/zones';

const get = () => apiWithProject.get(`${END_POINT}`);

const post = (params) => apiWithProject.post(`${END_POINT}`, {
  zoneId: params.id,
  zoneAlias: params.name,
});

const update = (params) => apiWithProject.put(`${END_POINT}`, {
  zoneId: params.id,
  zoneAlias: params.name,
});

const deletion = (zone) => apiWithProject.delete(`${END_POINT}`, {
  data: {
    zoneId: zone,
  },
}).catch(() => {
    return null
  });

const Zone = { get, post, update, deletion };

export default Zone;
