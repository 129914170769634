import { apiWithProject } from './greenetworkAPI';

const END_POINT = '/gateways';

const register = (key, name_eui, name) => apiWithProject.post(`${END_POINT}`, {
  key,
  name_eui,
  name,
});

const GatewayGreen = { register };

export default GatewayGreen;
