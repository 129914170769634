import { apiWithProject } from './greenetworkAPI';

const END_POINT = '/logs';

const get = () => apiWithProject.get(`${END_POINT}?len=500`);

const getQty = (qty) => apiWithProject.get(`${END_POINT}?len=${qty}`)
  .then((res) => res)
  .catch((err) => console.log(err));

const getDate = (timestamp) => apiWithProject.get(`${END_POINT}?since=${timestamp}&len=500`);

const getTarget = (target) => apiWithProject.get(`${END_POINT}?targets=${target}`);

const getSchedules = (millis, gateway) => apiWithProject.get(`${END_POINT}/locals?since=${millis}&origin=schedule&source=${gateway}`);

const getStatus = (id) => apiWithProject.get(`${END_POINT}/message_status?commandId=${id}`);

const Logger = {
  get, getQty, getDate, getTarget, getSchedules, getStatus,
};

export default Logger;
