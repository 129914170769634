// import moment from 'moment/src/moment';
import constants from '@/constants';

export const messages = {
  namespaced: true,
  state: {
    messages: [],
  },
  getters: {
    get_message_by_id(state, message_id) {
      return state.messages.find((message) => message.get_message_id() === message_id);
    },
    get_messages(state) {
      return state.messages;
    },
    there_are_pending_messages_on_setup_zone(state) {
      return state.messages.filter(
        (message) => (message.operation === 'zone-setup' || message.operation === 'config') && message.pending,
      ).length;
    },
    there_are_pending_messages_on_schedules(state) {
      return state.messages.filter(
        (message) => message.operation && message.operation.includes('schedule') && message.pending,
      ).length !== 0;
    },
  },
  actions: {
    add_message({ state, commit, dispatch }, message) {
      commit('ADD_MESSAGE', message);
    },
    remove_message({ commit, dispatch }, id_message) {
      return commit('REMOVE_MESSAGE', id_message);
    },
    serve_message({ state, commit, dispatch }, payload) {
      const { id, ack_result } = payload;
      const message = state.messages.find((message) => message.get_message_id() === id); // test if the given object has reference to the original...

      // serving the acknowledgment messages
      if (message) { commit('HANDLE_ACK_RESULT', { message, ack_result }); }
    },
    clean_messages({ state, commit }, payload) {
      commit('CLEAN_ALL_MESSAGES');
    },
  },
  mutations: {
    ADD_MESSAGE(state, message) {
      message.pending = true;
      // message.timestamp = moment(); // this is overriding the original timestamp set with Date object (see socket module)

      message.timeout = setTimeout((message, messages_array) => {
        // modify the message on the array
        const message_index = messages_array
          .findIndex((m) => m.get_message_id() === message.get_message_id());

        if (message_index >= 0) {
          messages_array[message_index].pending = false;
          messages_array[message_index].ack_result = false; // imaginary result
        }

        if (message.ackCallback) { message.ackCallback(false); }
      }, constants.TIMEOUT_FOR_RESPONSE, message, state.messages);

      state.messages.push(message);
    },
    REMOVE_MESSAGE(state, message_id) {
      return state.messages.splice(state.messages.findIndex((message) => message.get_message_id() === message_id), 1);
    },
    HANDLE_ACK_RESULT(state, payload) {
      const { message, ack_result } = payload; // message it's an ApiMessage object
      if (ack_result !== null) {
        // adding properties to that ApiMessage instance
        message.ack_result = ack_result;
        message.pending = false;
        if (message.ackCallback) { message.ackCallback(ack_result); }
        // clear timeout property
        clearTimeout(message.timeout);
      }
    },
    CLEAN_ALL_MESSAGES(state) {
      const { messages } = state;
      messages.splice(0, messages.length);// https://stackoverflow.com/questions/1232040/how-do-i-empty-an-array-in-javascript
    },

  },
};
