import Vue from 'vue';
import Vuex from 'vuex';
import { instructions } from '@/store/modules/instructions';
import { nodes } from '@/store/modules/nodes';
import { signup } from '@/store/modules/signup';
import { authentication } from './modules/authentication';
import { alert } from './modules/alert';
import { app } from './modules/app';
import { messages } from './modules/messages';
import { zones } from './modules/zones';
import { gateways } from './modules/gateways';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    alert,
    authentication,
    app,
    messages,
    instructions,
    nodes,
    signup,
    zones,
    gateways
  },
});
