<template>
  <v-navigation-drawer v-model="inputValue" :mini-variant.sync="mini" permanent expand-on-hover hide-overlay app clipped mini-variant-width="55">
    
    <div class="head">
      <v-list-item>
        <v-list-item-avatar color="primary">
          <v-icon dark>mdi-package</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="title text-capitalize font-weight-bold" style="font-family: Montserrat !important;">{{ project ? project : 'Unasigned project' }}</v-list-item-title>
          <v-list-item-subtitle class="button grey--text text--darken-4" style="font-family: Montserrat !important;">{{ user.name ? user.name : 'Username' }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>

    <div class="content slide">
      <v-list dense>
        <v-list-item-group color="primary">
          <v-list-item @click="$router.push({ name: 'home' }).catch(()=>{})" link>
            <v-list-item-action><v-icon>mdi-home</v-icon></v-list-item-action>
            <v-list-item-content><v-list-item-title>Home</v-list-item-title></v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <!-- <v-list-item-group>
        </v-list-item-group> -->

        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-action :class="{ 'ml-n4': !mini }"><v-icon>mdi-remote</v-icon></v-list-item-action>
              <v-list-item-content><v-list-item-title>Control</v-list-item-title></v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item :to="{ name: 'control' }" class="ml-n1" link>
            <v-list-item-title>
              <v-icon class="mr-3">mdi-lightbulb-on-outline</v-icon>
              Control Center
            </v-list-item-title>
          </v-list-item>
          <!-- :to="{ name: 'Lyt-Control' }" link-->
          <v-list-item class="ml-n1">
            <v-list-item-action>
              <v-icon>mdi-home-modern</v-icon>
            </v-list-item-action>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>Layout Control</v-list-item-title>
              <v-list-item-subtitle style="font-size:10px">In Maintenance</v-list-item-subtitle>
            </v-list-item-content>

            <!-- <v-icon class="mr-3">mdi-home-modern</v-icon>
            <v-list-item-title>
              Layout Control
              <v-list-item-subtitle style="font-size:10px">In Maintenance</v-list-item-subtitle>
            </v-list-item-title> -->
            
          </v-list-item>
        </v-list-group>

        <v-list-item-group>
          <v-list-item>
            <!-- :to="{ name: 'schedule' }" -->
            <v-list-item-action><v-icon>mdi-clock-outline</v-icon></v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Schedules</v-list-item-title>
              <v-list-item-subtitle style="font-size:10px">In Maintenance</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <!-- <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-action :class="{ 'ml-n4': !mini }"><v-icon>mdi-speedometer</v-icon></v-list-item-action>
              <v-list-item-content><v-list-item-title>Metering V.1.0</v-list-item-title></v-list-item-content>
            </v-list-item>
          </template>
          <v-list-group v-for="meter in meters" :key="meter.gateway.id" no-action sub-group :value="false">
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content><v-list-item-title>{{ meter.gateway.id }}</v-list-item-title></v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item v-for="(m, i) in meter.meters" :key="i" link :to="{ name: 'Meter', params: { id: m.name, name: meter.gateway.id, version: '1.0', new: false } }">
              <v-list-item-title v-text="m.name"></v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item class="ml-n5" link @click="()=>{ openMeter = true; version = '1.0'; mode = 'gateway'; product = 'meters' }"> 
            <v-list-item-title><v-icon class="mr-3">mdi-plus-circle-outline</v-icon>Add meters</v-list-item-title>
          </v-list-item>
        </v-list-group> -->

        <!-- color="primary" -->
        <v-list-item-group>
          <!-- :to="{ name: 'Downtime' }" -->
          <v-list-item>
            <v-list-item-action><v-icon>mdi-arrow-down-bold-box-outline</v-icon></v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Downtime Panel</v-list-item-title>
              <v-list-item-subtitle style="font-size:10px">In Maintenance</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-action :class="{ 'ml-n4': !mini }"><v-icon>mdi-battery-charging-80</v-icon></v-list-item-action>
              <v-list-item-content><v-list-item-title>Energy</v-list-item-title></v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item :to="{ name: 'MeterMenu' }" class="ml-n7" link>
            <v-list-item-title>
              <v-icon class="mr-3">mdi-speedometer</v-icon>
              Meters
            </v-list-item-title>
          </v-list-item>
          <!-- :to="{ name: 'GroupMenu' }" link -->
          <v-list-item class="ml-n7">
            <v-list-item-action>
              <v-icon>mdi-google-circles-communities</v-icon>
            </v-list-item-action>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>Groups</v-list-item-title>
              <v-list-item-subtitle style="font-size:10px">In Maintenance</v-list-item-subtitle>
            </v-list-item-content>
            <!-- <v-list-item-title>
              <v-icon class="mr-3">mdi-google-circles-communities</v-icon>
              Groups
            </v-list-item-title> -->
          </v-list-item>
          <!-- :to="{ name: 'Lyt-Meter' }" link -->
          <v-list-item class="ml-n7">
            <!-- <v-list-item-title>
              <v-icon class="mr-3">mdi-home-modern</v-icon>
              Metering Layout
            </v-list-item-title>
            <v-list-item-subtitle style="font-size:10px">
              In Maintenance
            </v-list-item-subtitle> -->
            <v-list-item-action>
              <v-icon>mdi-home-modern</v-icon>
            </v-list-item-action>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>Metering Layout</v-list-item-title>
              <v-list-item-subtitle style="font-size:10px">In Maintenance</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <!-- :to="{ name: 'Lyt-OneLine' }" link -->
          <v-list-item class="ml-n7">
            <!-- <v-list-item-title>
              <v-icon class="mr-3">mdi-lan</v-icon>
              One-Line Diagram Layout
              (In Maintenance)
            </v-list-item-title> -->
            <v-list-item-action>
              <v-icon>mdi-lan</v-icon>
            </v-list-item-action>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>One-Line Diagram Layout</v-list-item-title>
              <v-list-item-subtitle style="font-size:10px">In Maintenance</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- color="primary" -->
        <v-list-item-group>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Admin Panel
              </v-list-item-title>
              <v-list-item-subtitle style="font-size:10px">
                In Maintenance
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <!-- <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-action :class="{ 'ml-n4': !mini }">
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Admin Panel</v-list-item-title>
                <v-list-item-subtitle style="font-size:10px">In Maintenance</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item class="ml-n1" :to="{ name: 'Logger' }" link>
            <v-list-item-title>
              <v-icon class="mr-3">mdi-message-text-outline</v-icon>
              Log Center
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="ml-n1" :to="{ name: 'ProjectInfo' }" link>
            <v-list-item-title>
              <v-icon class="mr-3">mdi-information-outline</v-icon>
              Project Info
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="ml-n1" :to="{ name: 'Lyt-Modify' }" link>
            <v-list-item-title><v-icon class="mr-3">mdi-home-modern</v-icon>Layouts</v-list-item-title>
          </v-list-item>
        </v-list-group> -->

      </v-list>
    </div>

    <FormMeter :open="openMeter" :product="product" :index="idx" :edit_mode="editMode" :version="version" :mode="mode" :gtw="gtw" @close="openMeter = false"/>
    <AddGroupDialog ref="group" :open="openAddGroup" :editMode="editMode" @close="openAddGroup = false"/>

  </v-navigation-drawer>
</template>

<script>
import { db } from '../../plugins/firebase'
import FormMeter from "../FormMeter";
import AddGroupDialog from "./dialogs/groups/AddGroupDialog";
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
export default {
  name: "Drawer",
  components: { FormMeter, AddGroupDialog },
  data: () => ({
    mini: true,
    openMeter: false,
    meterSelected: null,
    gatewaySelected: null,
    idx: -1,
    editMode: false,
    iconChev: false,
    version: '1.0',
    mode: 'gateway',
    gtw: '',
    product: '',
    openAddGroup: false,
    groupSelected: {},
  }),
  computed: {
    inputValue: {
      get() {
        return this.$store.state.app.drawer.visible;
      },
      set(val) {
        this.setDrawer(val);
      }
    },
    ...mapState({
      green_gateways: state => state.gateways.greenGateways,
      meters: state => state.nodes.meters,
      user: state => state.authentication.user,
      groups: state => state.nodes.groups,
      project: state => state.authentication.project
    })
  },
  methods: {
    ...mapMutations({
      setDrawer: "app/SET_DRAWER_VISIBLE",
    }),
    ...mapGetters({
      logout: "authentication/logout"
    }),
    ...mapActions({ 
      retrieve_data: 'nodes/retrieve_data',
      setCapacity: 'nodes/setCapacity' 
    }),
    async refreshData(){
        await this.retrieve_data()
    },
  },
  created() {
    if (this.user != undefined) {
      db.collection(`usersGreenweb/${this.user.uid}/meters`).onSnapshot(res => {
          const changes = res.docChanges()
          if(changes.length > 0){
              localStorage.removeItem('meters')
              this.refreshData()
          }
      })
      db.collection(`usersGreenweb/${this.user.uid}/groups`).onSnapshot(res => {
          const changes = res.docChanges()
          if(changes.length > 0){
              localStorage.removeItem('groups')
              this.refreshData()
          }
      })
    }
  },
};
</script>

<style scoped>
.head {
  border-bottom: 1px solid rgb(190 190 190 / 58%);
}
.slide {
  overflow: hidden;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.content {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 48px;
}
.bottom-controllers {
  color: rgba(0, 0, 0, 0.655);
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  padding: 6px 12px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: white;
  border-top: 1px solid rgb(190 190 190 / 58%);
}
</style>
