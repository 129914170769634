import moment from 'moment';
import { nodes_service, zones_service } from '@/_services';
import { db, auth, storage } from '../../plugins/firebase';
import { Device, Gateway, Readings, Logger, MeterGreen, Groups } from '../../api';
import { KWH } from '../../components/graphs/props-types';
import { messages } from './messages';
export const nodes = {
  namespaced: true,
  collection: null,
  state: {
    nodes: [],
    zones: [],
    nodes_loader: false,
    zones_loader: false,
    schedules: [],
    meters: [],
    zoneIdx: '',
    scheduleIdx: '',
    layoutIdx: '',
    apiGateways: [],
    cost: 0,
    terminationDate: '',
    monthlyCost: 0,
    lightsRegistered: [],
    layouts: [],
    layoutMeter: null,
    layoutOneLine: null,
    ledLamp: [],
    oldLamp: [],
    colors: null,
    capacityDT: 0,
    groups: [],
  },
  getters: {
    get_node_by_id(state, node_id) {
      return state.nodes.find((node) => node.node_id === node_id);
    },
    get_zone_by_id(state, zone_id) {
      return state.zones.find((zone) => zone.zone_id === zone_id);
    },
    get_nodes(state) {
      return state.nodes;
    },
    get_zones(state) {
      return state.zones;
    },
    get_nodes_loader(state) {
      return state.nodes_loader;
    },
    get_zones_loader(state) {
      return state.zones_loader;
    },
  },
  actions: {
    async retrieve_data({ state, commit, dispatch }) {
      if (!auth.currentUser) return;
      const user_id = auth.currentUser.uid;
      commit('UPDATE_COLLECTION', user_id);

      const minutesToDanger = JSON.parse(localStorage.getItem('min2Dgr'));
      if (minutesToDanger === null) {
        const info = await db.doc(`${state.collection}`).get(); 
        const minutesToDangerFB = info.data().minutesToDanger;
        localStorage.setItem('min2Dgr', JSON.stringify(minutesToDangerFB));
      } else localStorage.setItem('min2Dgr', JSON.stringify(minutesToDanger));

      const zoneId = JSON.parse(localStorage.getItem('zone_idx'));
      if (zoneId === null) {
        const info = await db.doc(`${state.collection}`).get(); 
        const zoneIdFB = info.data().zone_id;
        localStorage.setItem('zone_idx', JSON.stringify(zoneIdFB));
        commit('SET_ZONE_ID', zoneIdFB)
      } else {
        commit('SET_ZONE_ID', zoneId)
      }

      const scheduleId = JSON.parse(localStorage.getItem('schedule_idx'));
      if (scheduleId === null) {
        const info = await db.doc(`${state.collection}`).get(); 
        const scheduleIdFB = info.data().schedule_id;
        localStorage.setItem('schedule_idx', JSON.stringify(scheduleIdFB));
        commit('SET_SCHEDULE_ID', scheduleIdFB)
      } else {
        commit('SET_SCHEDULE_ID', scheduleId)
      }

      const layoutId = JSON.parse(localStorage.getItem('layout_idx'));
      if (layoutId === null) {
        const info = await db.doc(`${state.collection}`).get(); 
        const layoutIdFB = info.data().layout_id;
        localStorage.setItem('layout_idx', JSON.stringify(layoutIdFB));
        commit('SET_LAYOUT_ID', layoutIdFB)
      } else {
        commit('SET_LAYOUT_ID', layoutId)
      }

      const cost = JSON.parse(localStorage.getItem('cost'));
      if (cost === null) {
        const info = await db.doc(`${state.collection}`).get(); 
        const costFB = info.data().cost != undefined ? info.data().cost : 0;
        localStorage.setItem('cost', JSON.stringify(costFB));
        commit('SET_COST', costFB)
      } else {
        commit('SET_COST', cost)
      }

      const monthlyCost = JSON.parse(localStorage.getItem('cost_month'));
      if (monthlyCost === null) {
        const info = await db.doc(`${state.collection}`).get(); 
        const monthlyCostFB = info.data().cost_month != undefined ? info.data().cost_month : 0;
        localStorage.setItem('cost_month', JSON.stringify(monthlyCostFB));
        commit('SET_MONTHLY_COST', monthlyCostFB)
      } else {
        commit('SET_MONTHLY_COST', monthlyCost)
      }

      const terminationDate = JSON.parse(localStorage.getItem('tdate'));
      if (terminationDate === null) {
        const info = await db.doc(`${state.collection}`).get(); 
        const terminationDateFB = info.data().termination_date != undefined ? info.data().termination_date : '';
        localStorage.setItem('tdate', JSON.stringify(terminationDateFB));
        commit('SET_TERMINATION_DATE', terminationDateFB)
      } else {
        commit('SET_TERMINATION_DATE', terminationDate)
      }

      await dispatch('gateways/fetchGateways', null, { root:true })

      const meters = JSON.parse(localStorage.getItem('meters'));
      if (meters === null) {
        const metersFB = await db.collection(`${state.collection}/meters`).get(); 
        const metersMapped = metersFB.docs.map((g) => g.data());
        localStorage.setItem('meters', JSON.stringify(metersMapped));
        commit('SET_METERS', metersMapped)
      } else {
        commit('SET_METERS', meters)
      }

      await dispatch('gateways/fetchGreenGateways', null, { root:true })

      const ledLamps = JSON.parse(localStorage.getItem('ledLamps'));
      if (ledLamps === null) {
        const ledLampsFB = await db.collection(`${state.collection}/ledLamp`).get(); 
        const ledLampsMapped = ledLampsFB.docs.map((g) => g.data());
        localStorage.setItem('ledLamps', JSON.stringify(ledLampsMapped)); 
        commit('SET_LED_LAMP', ledLampsMapped)
      } else {
        commit('SET_LED_LAMP', ledLamps)
      }

      const oldLamps = JSON.parse(localStorage.getItem('oldLamps'));
      if (oldLamps === null) {
        const oldLampsFB = await db.collection(`${state.collection}/oldLamp`).get(); 
        const oldLampsMapped = oldLampsFB.docs.map((g) => g.data());
        localStorage.setItem('oldLamps', JSON.stringify(oldLampsMapped)); 
        commit('SET_OLD_LAMP', oldLampsMapped)
      } else {
        commit('SET_OLD_LAMP', oldLamps)
      }

      const schedules = JSON.parse(localStorage.getItem('schedules'));
      if (schedules === null) {
        const schedulesFB = await db.collection(`${state.collection}/schedules`).get(); 
        const schedulesMapped = schedulesFB.docs.map((s) => s.data());
        localStorage.setItem('schedules', JSON.stringify(schedulesMapped)); 
        commit('SET_SCHEDULES', schedulesMapped)
      } else {
        commit('SET_SCHEDULES', schedules)
      }

      const layouts = JSON.parse(localStorage.getItem('layouts'));
      if (layouts === null) {
        const layoutsFB = await db.collection(`${state.collection}/layouts`).where('type', '==', 'control').get();
        const layoutsMapped = layoutsFB.docs.map((s) => s.data());
        localStorage.setItem('layouts', JSON.stringify(layoutsMapped)); 
        commit('SET_LAYOUTS', layoutsMapped)
      } else {
        commit('SET_LAYOUTS', layouts)
      }

      const layoutMeterLocal = JSON.parse(localStorage.getItem('lyt_meter'))
      if (layoutMeterLocal) {
        commit('SET_LAYOUT_METERS', layoutMeterLocal)
      } else {
        const responseLayout = await db.doc(`${state.collection}/layouts/LayoutMeter`).get();
        if (responseLayout.exists) {
          commit('SET_LAYOUT_METERS', responseLayout.data())
          localStorage.setItem('lyt_meter', JSON.stringify(responseLayout.data()))
        } else {
          commit('SET_LAYOUT_METERS', null)
        }
      }

      const layoutOneLineLocal = JSON.parse(localStorage.getItem('lyt_oneline'))
      if (layoutOneLineLocal) {
        commit('SET_LAYOUT_ONELINE', layoutOneLineLocal)
      } else {
        const responseLayout = await db.doc(`${state.collection}/layouts/LayoutOneLine`).get();
        if (responseLayout.exists) {
          commit('SET_LAYOUT_ONELINE', responseLayout.data())
          localStorage.setItem('lyt_oneline', JSON.stringify(responseLayout.data()))
        } else {
          commit('SET_LAYOUT_ONELINE', null)
        }
      }

      const lightsRegistered = JSON.parse(localStorage.getItem('regLights'));
      if (lightsRegistered === null) {
        const lightsRegisteredFB = await db.collection(`${state.collection}/regLights`).get();
        const lightsRegisteredMapped = lightsRegisteredFB.docs.map((s) => s.data());
        localStorage.setItem('regLights', JSON.stringify(lightsRegisteredMapped)); 
        commit('SET_LIGHTS_REGISTERED', lightsRegisteredMapped)
      } else {
        commit('SET_LIGHTS_REGISTERED', lightsRegistered)
      }

      const colors = JSON.parse(localStorage.getItem('colors'));
      if (colors) {
        commit('SET_COLORS', colors)        
      } else {
        localStorage.removeItem('colors');
        const info = await db.doc(`${state.collection}`).get(); 
        const colorRes = info.data().colors ?? { primary: '#06D676', secondary: '#023243' };
        localStorage.setItem('colors', JSON.stringify(colorRes));
        commit('SET_COLORS', colorRes)
      }

      let groups = JSON.parse(localStorage.getItem('groups'))
      if (groups === null) {
        let groupsAPI = await Groups.getGroups()
        localStorage.setItem('groups', JSON.stringify(groupsAPI.data)); 
        commit('SET_GROUPS', groupsAPI.data)
      } else {
        commit('SET_GROUPS', groups)
      }

    },
    async fetchNodes({ commit }, zones){
      const DEFAULT_ZONE = 'Z00'
      const nodesAPI = await Device.get()
      const nodesArray = []
      let assignedZone
      nodesAPI.data.forEach(node => {
        if(node.class !== 'Neurolink') return
        nodesArray.push(node)
        if(node.functions.hasOwnProperty('ZoneNode')){
          assignedZone = zones.find(zone => zone.id === node.functions.ZoneNode.zoneID)
          if(!assignedZone) {
            assignedZone = zones.find(zone => zone.id === DEFAULT_ZONE)
            assignedZone.nodes.push({ id: node.functions.Basic.assetId, zone_id: DEFAULT_ZONE })
            return
          }
          assignedZone.nodes.push({ id: node.functions.Basic.assetId, zone_id: assignedZone.id })
        } else {
          assignedZone = zones.find(zone => zone.id === DEFAULT_ZONE)
          assignedZone.nodes.push({ id: node.functions.Basic.assetId, zone_id: DEFAULT_ZONE })
        }
      })
      commit('SET_NODES', nodesArray)
      return zones
    },
    setCapacity({ commit }, data) {
      commit('SET_CAPACITY_DT', data);
    },
    async addNode({ dispatch }, data) {
      try {
        const node = data.nd;
        const params = { function: 'Basic', class: 'Neurolink', displayName: data.nd };
        const mongoValidation = await Device.post(node, params);
        if(!mongoValidation) return false
        if(mongoValidation.data.status !== 200) return false
        dispatch('zones/fetchZones', null, { root: true } )
        return true
      } catch (error) {
        console.log(error);
        return false
      }
    },
    getScheduleID({ state }) {
      const id = JSON.parse(localStorage.getItem('schedule_idx'));
      let char1; let
        char2;
      const arr = [];
      const arrLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
      for (let i = 0; i < id.length; i++) arr.push(id[i]);
      char1 = Number(arr[0]);
      char2 = Number(arr[1]);
      if (!isNaN(char1) && !isNaN(char2)) {
        if (char2 < 9) char2 += 1;
        else if (char1 < 9) { char1 += 1; char2 = 0; } else { char1 = 0; char2 = 'A'; }
      } else if (!isNaN(char1) && isNaN(char2)) {
        if (arr[1] != 'Z') { let idx = arrLetters.findIndex((i) => i === arr[1]); idx += 1; char2 = arrLetters[idx]; } else if (char1 < 9) { char1 += 1; char2 = 'A'; } else { char1 = 'A'; char2 = 0; }
      } else if (isNaN(char1) && !isNaN(char2)) {
        char1 = arr[0];
        if (arr[1] < 9) char2 += 1;
        else if (char1 != 'Z') { let idx = arrLetters.findIndex((i) => i === char1); idx += 1; char1 = arrLetters[idx]; char2 = 0; } else { char1 = 'A'; char2 = 'A'; }
      } else if (arr[1] != 'Z') {
        let idx = arrLetters.findIndex((i) => i === arr[1]);
        idx += 1;
        char1 = arr[0];
        char2 = arrLetters[idx];
      } else if (arr[0] != 'Z') { let idx = arrLetters.findIndex((i) => i === arr[0]); idx += 1; char1 = arrLetters[idx]; char2 = 'A'; } else return;
      const char = ''.concat(char1, char2);
      return char;
    },
    async fetchMongoValidation({ state }, mongoStatus){
      let mongoValidation
      const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      for (let index = 1; index <= 16; index++) {
          await wait(1000);
          mongoValidation = await Logger.getStatus(mongoStatus.data);
          if(!mongoValidation.data) continue;
          if (mongoValidation.data) {
            if (mongoValidation.data.status === 'RECEIVED') break; 
          }
      }
      return mongoValidation
    },
    async controlNodes({ dispatch }, data) {
      try {
        const { nodes, config, gateway } = data;
        const mongoStatus = await Gateway.sendInstructions(nodes, 'devices', config, gateway)
        const mongoValidation = await dispatch('fetchMongoValidation', mongoStatus)
        if (mongoValidation.data === null) return 'ERROR';
        if (mongoValidation.data.status !== 'RECEIVED') return mongoValidation.data.status;
        return mongoValidation.data.status;
      } catch (error) {
        console.log(error);
        return 'ERROR';
      }
    },
    async deleteNode({ dispatch }, data) {
      let deletedItem = true
      try {
        const { nodeId } = data
        const mongoValidation = await Device.deletion(nodeId);
        if(!mongoValidation.data) throw new Error('Internal error in database when deleting selected node, please contact your provider.')
        dispatch('zones/fetchZones', null, { root: true } )
        return { deletedItem }
      } catch (error) {
        deletedItem = false
        return { error, deletedItem }
      }
    },
    async addLightRegister({ state, commit }, data){
      let isLightAdded = true
      try {
        const collectionDoc = `${state.collection}/regLights`;
        const firestoreTransaction = db.runTransaction(async () => {
          await db.doc(`${collectionDoc}/${data.id}`).set(data);
        });
        await firestoreTransaction;
        commit('ADD_REG_LIGHT', data)
        return { isLightAdded }
      } catch(error) {
        isLightAdded = false
        return { isLightAdded, error }
      }
    },
    async deleteRegLights({ state, commit }, data){
      const collectionDoc = `${state.collection}/regLights`;
      try {
        const firestoreTransaction = db.runTransaction(async (transaction) => {
          const regLightRef = db.doc(`${collectionDoc}/${data}`);
          const currentLightRefSnapshot = await transaction.get(regLightRef)
          if (!currentLightRefSnapshot.exists) throw new Error();
          transaction.delete(regLightRef);
        });
        await firestoreTransaction;
        commit('DEL_REG_LIGHT', data)
        return true
      } catch {
        return false
      }
    },
    async addSchedule({ state, rootState, dispatch }, config) {
      try {
        const zonesArray = rootState.zones.zonesArray;

        let scheduleId = await dispatch('getScheduleID');

        localStorage.setItem('schedule_idx', JSON.stringify(scheduleId));
        await db.doc(`${state.collection}`).update({ schedule_id: scheduleId });

        const zoneNames = [];
        config.zones.forEach((zoneID) => { 
          const zoneSelected = zonesArray.find(zone => zone.id === zoneID); 
          zoneNames.push(zoneSelected.name); 
        });

        const collection = `${state.collection}/schedules`;
        const timeFormatted = `${config.HR}:00`;

        const weekdaysIndexes = config.WD;
        const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        const formattedWeekdays = weekdaysIndexes
                                                .map(wd => weekdays[wd])
                                                .join(',')

        const { zones } = config;

        const params = { ...config }

        params.ID = `S${scheduleId}`
        params.HR = timeFormatted
        params.WD = formattedWeekdays
        params.E = true
        params.A = config.A

        const gateway = rootState.gateways.mainGateway
        
        const mongoStatus = await Gateway.sendScheduleConfiguration(zones, params, gateway)

        const mongoValidation = await dispatch('fetchMongoValidation', mongoStatus)

        if (mongoValidation.data.status !== 'RECEIVED') return mongoValidation.data.status;

        const scheduleObj = {
          config: {
            d: config.D, il: config.LV, it: config.T, max: config.LH, min: config.LL,
          },
          confirmed: config.A,
          days: config.WD,
          enable: true,
          time: config.HR,
          zones: zoneNames,
          schedule_id: `S${scheduleId}`,
          name: config.name,
        };

        await db.doc(`${collection}/S${scheduleId}`).set(scheduleObj);
        await db.doc(`${collection}/S${scheduleId}`).update({ schedule_id: `S${scheduleId}` });

        return mongoValidation.data.status;
      } catch (error) {
        console.log(error);
      }
    },
    async editSchedule({ state, rootState, commit, dispatch }, data) {
      try {
        const { config, index } = data;

        const collection = `${state.collection}/schedules`;
        const schedulesArray = state.schedules;
        const zonesArray = rootState.zones.zonesArray
        const zoneNames = [];
        const scheduleId = schedulesArray[index].schedule_id
        const timeFormatted = `${config.HR}:00`

        config.zones.forEach((zoneID) => { 
          const zoneSelected = zonesArray.find(zone => zone.id === zoneID); 
          zoneNames.push(zoneSelected.name); 
        });

        const weekdaysIndexes = config.WD;
        const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        const formattedWeekdays = weekdaysIndexes
                                                .map(wd => weekdays[wd])
                                                .join(',')
        const enableState = schedulesArray[index].enable

        const { zones } = config;

        const params = { ...config }

        params.ID = scheduleId
        params.HR = timeFormatted
        params.WD = formattedWeekdays
        params.E = enableState
        params.A = config.A

        const gateway = rootState.gateways.mainGateway
        
        const mongoStatus = await Gateway.sendScheduleConfiguration(zones, params, gateway)

        const mongoValidation = await dispatch('fetchMongoValidation', mongoStatus)

        if (mongoValidation.data.status !== 'RECEIVED') return mongoValidation.data.status;

        await db.doc(`${collection}/${schedulesArray[index].schedule_id}`).update({
          config: {
            d: config.D, il: config.LV, it: config.T, max: config.LH, min: config.LL,
          },
          confirmed: config.A,
          days: config.WD,
          time: config.HR,
          zones: zoneNames,
          name: config.name,
        });
        schedulesArray[index].config.d = config.D;
        schedulesArray[index].config.il = config.LV;
        schedulesArray[index].config.it = config.T;
        schedulesArray[index].config.max = config.LH;
        schedulesArray[index].config.min = config.LL;
        schedulesArray[index].confirmed = config.A;
        schedulesArray[index].days = config.WD;
        schedulesArray[index].time = config.HR;
        schedulesArray[index].zones = zoneNames;
        schedulesArray[index].name = config.name;
        commit('EDIT_SCHEDULE', schedulesArray);
        return mongoValidation.data.status;
      } catch (error) {
        console.log(error);
      }
    },
    async changeStatusControl({ state, rootState, commit, dispatch }, data) {
      const MODE = 'mode'
      const zones = [];
      const schedulesArray = state.schedules;
      const zonesArray = rootState.zones.zonesArray
      const collection = `${state.collection}/schedules`;
      const { st, type, idx } = data;
      const weekdaysIndexes = schedulesArray[idx].days;
      const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      const formattedWeekdays = weekdaysIndexes
                                              .map(wd => weekdays[wd])
                                              .join(',')

      const scheduleID = schedulesArray[idx].schedule_id;
      const timeFormatted = `${schedulesArray[idx].time}:00`;
      const params = { 
        E: schedulesArray[idx].enable,
        ID: scheduleID, 
        HR: timeFormatted, 
        WD: formattedWeekdays,
        D: schedulesArray[idx].config.d, 
        LH: schedulesArray[idx].config.max,
        LL: schedulesArray[idx].config.min,
        T: schedulesArray[idx].config.it,
        LV: schedulesArray[idx].config.il,
        A: st
      };      

      schedulesArray[idx].zones.forEach(zoneName => { 
        const zoneSelected = zonesArray.find(zone => zone.name === zoneName); 
        zones.push(zoneSelected.id); 
      });

      const gateway = rootState.gateways.mainGateway

      const mongoStatus = type === MODE 
                                      ? await Gateway.sendScheduleConfiguration(zones, params, gateway) 
                                      : await Gateway.scheduleEnabling(zones, scheduleID, st, gateway);

      const mongoValidation = await dispatch('fetchMongoValidation', mongoStatus)

      const propToModify = type === MODE 
                                        ? { confirmed: st } 
                                        : { enable: st }
      
      const propName = Object.keys(propToModify)

      if (mongoValidation.data.status != 'RECEIVED') return mongoValidation.data.status;

      await db.doc(`${collection}/${schedulesArray[idx].schedule_id}`).update(propToModify);

      schedulesArray[idx][propName[0]] = st

      commit('EDIT_SCHEDULE', schedulesArray);
      return mongoValidation.data.status;
    },
    async deleteSchedules({ state, rootState, commit, dispatch }, data) {
      try {
        const collection = `${state.collection}/schedules`;
        const zonesArray = rootState.zones.zonesArray
        const schedulesArray = state.schedules;
        const scheduleID = schedulesArray[data.scheduleIdx].schedule_id;
        const zones = [];
        
        const gateway = rootState.gateways.mainGateway
        schedulesArray[data.scheduleIdx].zones.forEach(zoneName => { 
          const zoneSelected = zonesArray.find(zone => zone.name === zoneName); 
          zones.push(zoneSelected.id); 
        });

        const mongoStatus = await Gateway.scheduleDeletion(zones, scheduleID, gateway);
        const mongoValidation = await dispatch('fetchMongoValidation', mongoStatus)
        if (mongoValidation.data.status != 'RECEIVED') return mongoValidation.data.status;

        const info = await db.doc(`${state.collection}`).get();
        const deletedSchedules = info.data().deleted_schedules;
        deletedSchedules.push({ id: schedulesArray[data.scheduleIdx].schedule_id, name: schedulesArray[data.scheduleIdx].name });
        await db.doc(`${state.collection}`).update({ deleted_schedules: deletedSchedules });
        await db.doc(`${collection}/${schedulesArray[data.scheduleIdx].schedule_id}`).delete();
        commit('DELETE_SCHEDULE', data);
        return mongoValidation.data.status;
      } catch (error) {
        console.log(error);
      }
    },
    async addMeterLayout({ state, commit, rootState }, data){
      let { info, image, docName, storageName, mutationName } = data
      return new Promise(async (resolve, reject) => {
        try {
          const collection = `${state.collection}/layouts`;
          await storage.ref(`users_greenweb/${rootState.authentication.user.uid}/${docName}/Layout1`).put(image);
          const imgURL = await storage.ref(`users_greenweb/${rootState.authentication.user.uid}/${docName}/Layout1`).getDownloadURL();
          info.img_url = imgURL
          await db.doc(`${collection}/${docName}`).set(info);
          localStorage.setItem(`${storageName}`, JSON.stringify(info));
          commit(`${mutationName}`, info)
          return resolve();
        } catch (error) {
          console.log(error);
          return reject();
        }
      });
    },
    async saveMetersInLayout({ state, commit }, data){
      let { metersData, docName, storageName, mutationName } = data
      return new Promise (async (resolve, reject) => {
        try {
          const meters = []
          const collection = `${state.collection}/layouts`;
          metersData.forEach(meter => {
            let {name, id, x, y} = meter
            meters.push({ name, id, x, y })
          });
          await db.doc(`${collection}/${docName}`).update({ meters });
          let layoutDoc = await db.doc(`${collection}/${docName}`).get()
          localStorage.setItem(`${storageName}`, JSON.stringify(layoutDoc.data()));
          commit(`${mutationName}`, layoutDoc.data())
          return resolve()
        } catch (error) {
          console.log(error);
          return reject()
        }
      })
      
    },
    async deleteMeterLayout({ state, commit, rootState }, data){
      return new Promise(async (resolve, reject) => {
        try {
          let { img_url, docName, storageName, mutationName } = data
          const fileRef = storage.refFromURL(img_url);
          const collection = `${state.collection}/layouts`;
          await fileRef.delete()
          await db.doc(`${collection}/${docName}`).delete();
          localStorage.removeItem(`${storageName}`);
          commit(`${mutationName}`, null)
          return resolve();
        } catch (error) {
          console.log(error);
          return reject();
        }
      });
    },
    async addLayout({
      state, commit, dispatch, rootState,
    }, data) {
      return new Promise(async (resolve, reject) => {
        try {
          const collection = `${state.collection}/layouts`;
          const lid = await dispatch('getLayoutID'); localStorage.setItem('layout_idx', JSON.stringify(lid));
          await db.doc(`${state.collection}`).update({ layout_id: lid });
          const image = data.img;
          await storage.ref(`users_greenweb/${rootState.authentication.user.uid}/L${lid}/${image.name}`).put(image);
          const imgURL = await storage.ref(`users_greenweb/${rootState.authentication.user.uid}/L${lid}/${image.name}`).getDownloadURL();
          data.zones.forEach((zone) => { if (zone.hasOwnProperty('ts')) delete zone.ts; });
          const obj = {
            description: data.description, img: image.name, img_url: imgURL, name: data.name, zones: data.zones, id: `L${lid}`, width: data.width, height: data.height, type: 'control'
          };
          await db.doc(`${collection}/L${lid}`).set(obj);
          return resolve();
        } catch (error) {
          console.log(error);
          return reject();
        }
      });
    },
    async editLayout({ state, commit }, data) {
      return new Promise(async (resolve, reject) => {
        try {
          const { layouts } = state;
          const collection = `${state.collection}/layouts`;
          const lyt = layouts.find((l) => l.img_url === data.img_url);
          data.zones.forEach((zone) => { if (zone.hasOwnProperty('ts')) delete zone.ts; });
          await db.doc(`${collection}/${lyt.id}`).update({ name: data.name, description: data.description, zones: data.zones });
          return resolve();
        } catch (error) {
          console.log(error);
          return reject();
        }
      });
    },
    async deleteLayout({ state, rootState }, data) {
      const collectionDoc = `${state.collection}/layouts`;
      const collectionStorage = `users_greenweb/${rootState.authentication.user.uid}`
      try {
        const firestoreTransaction = db.runTransaction(async (transaction) => {
          const layoutRef = db.doc(`${collectionDoc}/${data.id}`);
          const currentLayoutSnapshot = await transaction.get(layoutRef)
          if (!currentLayoutSnapshot.exists) throw new Error();
          transaction.delete(layoutRef);
          await storage.ref(`${collectionStorage}/${data.id}/${data.img}`).delete();
        });
        await firestoreTransaction;
        return true
      } catch {
        return false
      }
    },
    getLayoutID({ state }) {
      const id = JSON.parse(localStorage.getItem('layout_idx')); let char1; let
        char2; const arr = [];
      const arrLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
      for (let i = 0; i < id.length; i++) arr.push(id[i]);
      char1 = Number(arr[0]); char2 = Number(arr[1]);
      if (!isNaN(char1) && !isNaN(char2)) {
        if (char2 < 9) { char2 += 1; } else if (char1 < 9) { char1 += 1; char2 = 0; } else { char1 = 0; char2 = 'A'; }
      } else if (!isNaN(char1) && isNaN(char2)) {
        if (arr[1] != 'Z') { let idx = arrLetters.findIndex((i) => i === arr[1]); idx += 1; char2 = arrLetters[idx]; } else if (char1 < 9) { char1 += 1; char2 = 'A'; } else { char1 = 'A'; char2 = 0; }
      } else if (isNaN(char1) && !isNaN(char2)) {
        char1 = arr[0];
        if (arr[1] < 9) { char2 += 1; } else if (char1 != 'Z') { let idx = arrLetters.findIndex((i) => i === char1); idx += 1; char1 = arrLetters[idx]; char2 = 0; } else { char1 = 'A'; char2 = 'A'; }
      } else if (arr[1] != 'Z') { let idx = arrLetters.findIndex((i) => i === arr[1]); idx += 1; char1 = arr[0]; char2 = arrLetters[idx]; } else if (arr[0] != 'Z') { let idx = arrLetters.findIndex((i) => i === arr[0]); idx += 1; char1 = arrLetters[idx]; char2 = 'A'; } else { return; }
      const char = ''.concat(char1, char2); return char;
    },
    async addMeter({ state, commit }, data) {
      const collection = `${state.collection}/meters`;
      await db.doc(`${collection}/${data.gateway.id}`).set(data);
    },
    async addGroup({ commit }, data){
      try {
        const response = await Groups.createGroup(data);
        let groups = JSON.parse(localStorage.getItem('groups'))
        groups.push({...response.data})
        localStorage.setItem('groups', JSON.stringify(groups)); 
        commit('SET_GROUPS', groups)
        return true
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async editGroup({ commit }, data){
      const { id, ...rest } = data
      try {
        const response = await Groups.updateGroup(rest, id);
        let groups = JSON.parse(localStorage.getItem('groups'))
        groups = groups.map(group => group._id === id ? response.data : group);
        localStorage.setItem('groups', JSON.stringify(groups)); 
        commit('SET_GROUPS', groups)
        return true
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async deleteGroup({ commit }, data){
      const { _id } = data
      try {
        await Groups.deleteGroup(_id)
        let groups = JSON.parse(localStorage.getItem('groups'))
        groups = groups.filter((g) => g._id != data._id)
        localStorage.setItem('groups', JSON.stringify(groups)); 
        commit('SET_GROUPS', groups)
        return true
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async addMeterVersion({ state, commit }, data) {
      const { led, old } = data;
      let collection;
      const time = new Date();
      const gg = JSON.parse(localStorage.getItem('greenGateway'));

      if (led != '') {
        await MeterGreen.addDevice(led, led);
        collection = `${state.collection}/ledLamp`;
        await db.doc(`${collection}/led`).set({
          name: led, id: led, creationTime: time.getTime(), gateway: gg,
        });
      }
      if (old != '') {
        await MeterGreen.addDevice(old, old);
        collection = `${state.collection}/oldLamp`;
        await db.doc(`${collection}/old`).set({
          name: old, id: old, creationTime: time.getTime(), gateway: gg,
        });
      }
    },
    async editMeterVersion({ state, commit }, data) {
      const { led, old } = data;
      let doc;
      if (led != '') {
        doc = await db.doc(`${state.collection}/ledLamp/led`).get();
        await MeterGreen.updateDisplayName(doc.data().id, led);
        await db.doc(`${state.collection}/ledLamp/led`).update({ name: led });
      }
      if (old != '') {
        doc = await db.doc(`${state.collection}/oldLamp/old`).get();
        await MeterGreen.updateDisplayName(doc.data().id, old);
        await db.doc(`${state.collection}/oldLamp/old`).update({ name: old });
      }
    },
    async getHistoricData({ state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        Readings.daySummary(
          data.id_meter,
          {
            start: new Date().getTime() - 1000 * 60 * 60 * 24 * 30,
            end: new Date().getTime(),
          },
        )
          .then((response) => {
            const data = response;
            resolve({ data });
          })
          .catch((error) => {
            console.log(error);
            reject({ data: [] });
          });
      });
    },
    async getLineGraphData({ state }, data) {
      const { cred, times } = data;
      const dataArr = [];
      for (let index = 0; index < times.length; index++) {
        const elem = times[index];
        const res = await Readings.byMeter(
          { id: cred.id, key: cred.key },
          { end: new Date().getTime(), start: new Date().getTime() - 1000 * 60 * 60 * elem },
        );
        dataArr.push(res);
      }
      return dataArr;
    },
    async getBarInfo({ state }, data) {
      const {
        cred, time, mode, compare, custom, qty,
      } = data;
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      if (compare) {
        if (mode === 'hour') {
          let pres; let past; let t1; let t2;

          t1 = time[1].setHours(0, 0, 0, 0);
          t2 = time[0].setHours(0, 0, 0, 0);

          pres = await Readings.byMeter({ id: cred.id, key: cred.key }, { end: t1 + 1000 * 60 * 60 * 24, start: t1 });
          past = await Readings.byMeter({ id: cred.id, key: cred.key }, { end: t2 + 1000 * 60 * 60 * 24, start: t2 });

          const arrData = []; const arrDates = []; const finalRes = [];

          if (pres.data.length > 0) { arrData.push(pres); arrDates.push(t1); }
          if (past.data.length > 0) { arrData.push(past); arrDates.push(t2); }
          if (arrData.length <= 0) return;

          for (let index = 0; index < arrDates.length; index++) {
            const elem = arrDates[index];
            const tf = moment(elem).format('L');
            const [tm, td] = tf.split('/');
            const month_t = Number(tm);
            const day_t = Number(td);
            const t_prop = `${months[month_t - 1]} ${day_t}`;
            if (arrData[index].data.length > 1) {
              let t_valInit = new Date(arrData[index].data[0].timestamp).getHours(); let t_numsHrs = []; const t_hrsReg = []; const t_testArr = [];
              arrData[index].data.forEach((element) => {
                const t_date = new Date(element.timestamp);
                if (t_date.getHours() === t_valInit) {
                  t_numsHrs.push(t_valInit);
                } else {
                  t_hrsReg.push({ hr: t_valInit, qty: t_numsHrs.length, orDate: t1 });
                  t_valInit = t_date.getHours();
                  t1 = t_date;
                  t_numsHrs = []; t_numsHrs.push(t_date.getHours());
                }
                t_testArr.push(element.lines[0].kwht);
              });
              const t_diffsArr = [];
              for (let index = 0; index < t_testArr.length - 1; index++) { const element = t_testArr[index + 1] - t_testArr[index]; t_diffsArr.push(element); }
              t_diffsArr.push(0);
              t_hrsReg.push({ hr: t_valInit, qty: t_numsHrs.length, orDate: typeof t1 === 'number' ? t1 : t1.getTime() });
              const t_helpArr = []; let t_helpAcc = 0;
              t_hrsReg.forEach((element) => { t_helpAcc += element.qty; t_helpArr.push(t_helpAcc); });
              let t_starter = 0; let t_cnt = 0; const t_resArr = [];
              t_hrsReg.forEach((element) => {
                let acc = 0;
                for (let index = t_starter; index < t_helpArr[t_cnt]; index++) { acc += t_diffsArr[index]; }
                const obj = {
                  lines: [{
                    nline: 1, kwht: Number(acc.toFixed(2)), amps: 0, volts: 0, watts: 0,
                  }],
                  _id: { hour: element.hr, od: element.orDate },
                };
                t_resArr.push(obj);
                t_starter = t_helpArr[t_cnt];
                t_cnt += 1;
              });
              const resObj = { prop: t_prop, data: t_resArr };
              finalRes.push(resObj);
            }
          }

          const tod_hours = { categories: [], series: [{ line: '0', prop: KWH, data: [] }] };
          const yes_hours = { categories: [], series: [{ line: '0', prop: KWH, data: [] }] };

          const twoDates = [];

          if (pres.data.length > 0) { twoDates.push(tod_hours); }
          if (past.data.length > 0) { twoDates.push(yes_hours); }

          for (let index = 0; index < twoDates.length; index++) {
            const date = finalRes[index];
            if (date) {
              date.data.forEach((reading) => {
                const dt = `${reading._id.hour}:00`;
                const { od } = reading._id;
                twoDates[index].series.forEach((serie) => { serie.data.push({ x: od, y: reading.lines[serie.line][serie.prop].toFixed(2) }); });
                twoDates[index].categories.push(dt);
              });
            }
          }

          let tod_cnt; let yes_cnt; let maxHours; let tobj; let yobj;
          const hours_format = { categories: [], series: [] };

          if (twoDates.length === 2) {
            tod_cnt = twoDates[0].categories.length; const t_vals = [];
            twoDates[0].series[0].data.forEach((element, i) => { t_vals.push({ x: i, y: Number(element.y) }); });
            yes_cnt = twoDates[1].categories.length; const y_vals = [];
            twoDates[1].series[0].data.forEach((element, i) => { y_vals.push({ x: i, y: Number(element.y) }); });
            maxHours = tod_cnt > yes_cnt ? twoDates[0].categories : twoDates[1].categories;
            hours_format.categories = maxHours;
            tobj = { data: t_vals, prop: finalRes[0].prop };
            yobj = { data: y_vals, prop: finalRes[1].prop };
            hours_format.series = [yobj, tobj];
          } else {
            tod_cnt = twoDates[0].categories.length; const t_vals = [];
            twoDates[0].series[0].data.forEach((element, i) => { t_vals.push({ x: i, y: Number(element.y) }); });
            maxHours = tod_cnt;
            hours_format.categories = maxHours;
            tobj = { data: t_vals, prop: finalRes[0].prop };
            hours_format.series = [tobj];
          }
          return hours_format;
        } if (mode === 'day') {
          let m1; let y1; let m2; let y2;

          m1 = time[0].getMonth() + 1;
          y1 = time[0].getFullYear();
          m2 = time[1].getMonth() + 1;
          y2 = time[1].getFullYear();

          const periods = [{ year: y2, month: m2 }, { year: y1, month: m1 }];

          const per_data = [];
          for (let i = 0; i < periods.length; i++) {
            const elem = periods[i];
            const m = new Date(elem.year, elem.month - 1, 1);
            const cnt = new Date(elem.year, elem.month, 0).getDate();
            const data = await Readings.daySummary(cred.id, { start: m.getTime(), end: m.getTime() + 1000 * 60 * 60 * 24 * cnt });
            let c = 0;
            if (data.length > 0) {
              for (let j = 0; j < data.length - 1; j++) {
                c += 1;
                if (data[j]._id.day > data[j + 1]._id.day) {
                  for (let k = c; k >= 1; k--) {
                    if (data[k]._id.day < data[k - 1]._id.day) {
                      const num = data[k];
                      data[k] = data[k - 1];
                      data[k - 1] = num;
                    }
                  }
                }
              }
              per_data.push(data);
            }
          }

          if (per_data.length === 0) return;

          const aformated = { categories: [], series: [{ line: '0', prop: KWH, data: [] }] };
          const pformated = { categories: [], series: [{ line: '0', prop: KWH, data: [] }] };
          let twoDates;

          if (per_data.length === 2) {
            twoDates = [aformated, pformated];
          } else {
            twoDates = [aformated];
          }

          for (let index = 0; index < twoDates.length; index++) {
            const date = per_data[index];
            if (date.length > 0) {
              date.forEach((reading) => {
                const dt = new Date(reading._id.year, reading._id.month - 1, reading._id.day);
                twoDates[index].series.forEach((serie) => {
                  serie.data.push({
                    x: dt.getTime(),
                    y: reading.lines[serie.line][serie.prop].toFixed(2),
                  });
                });
                twoDates[index].categories.push(dt.toLocaleDateString());
              });
            }
          }

          const dformat = { categories: [], series: [] };

          if (twoDates[0].categories.length === 0 && twoDates[1].categories.length === 0) return dformat;

          let acnt; let pcnt; let maxDays; let maxLng; const cat = [];
          let aMonth; let pMonth; let am2; let pm2; const adays = []; const pdays = []; let aobj; let pobj;

          if (per_data.length === 2) {
            acnt = per_data[0].length;
            pcnt = per_data[1].length;
            maxDays = acnt > pcnt ? twoDates[0].categories : twoDates[1].categories;
            aMonth = per_data[0][0]._id.month;
            pMonth = per_data[1][0]._id.month;
            am2 = months[aMonth - 1];
            pm2 = months[pMonth - 1];
            twoDates[0].series[0].data.forEach((element) => { adays.push({ x: new Date(element.x).getDate(), y: Number(element.y) }); });
            twoDates[1].series[0].data.forEach((element) => { pdays.push({ x: new Date(element.x).getDate(), y: Number(element.y) }); });
            aobj = { data: [], prop: am2 };
            pobj = { data: [], prop: pm2 };
            dformat.series = [pobj, aobj];
          } else {
            acnt = per_data[0].length;
            maxDays = twoDates[0].categories;
            aMonth = per_data[0][0]._id.month;
            am2 = months[aMonth - 1];
            twoDates[0].series[0].data.forEach((element) => { adays.push({ x: new Date(element.x).getDate(), y: Number(element.y) }); });
            aobj = { data: [], prop: am2 };
            dformat.series = [aobj];
          }

          maxLng = maxDays.length;
          maxDays.forEach((element) => {
            const [d, m, y] = element.split('/');
            const nd = Number(d); const nm = Number(m); const ny = Number(y);
            const nDate = new Date(ny, nm - 1, nd);
            cat.push(nDate);
          });
          dformat.categories = cat;

          if (per_data.length === 2) {
            for (let index = 0; index < maxLng; index++) {
              const pdata = pdays.find((e) => e.x === index + 1);
              const adata = adays.find((e) => e.x === index + 1);
              pdata ? dformat.series[0].data.push(pdata) : dformat.series[0].data.push({ x: index + 1, y: null });
              adata ? dformat.series[1].data.push(adata) : dformat.series[1].data.push({ x: index + 1, y: null });
            }
          } else {
            for (let index = 0; index < maxLng; index++) {
              const adata = adays.find((e) => e.x === index + 1);
              adata ? dformat.series[0].data.push(adata) : dformat.series[0].data.push({ x: index + 1, y: null });
            }
          }

          return dformat;
        }
        let pm; let pyr; let cm; let cyr;

        pyr = time[0].getFullYear(); cyr = time[1].getFullYear();
        pm = 11; cm = time[0].getMonth();

        let per_data; const per_data2 = [];
        const array = [{ m: pm, y: pyr }, { m: cm, y: cyr }];

        for (let index = 0; index < array.length; index++) {
          const elem1 = array[index];
          per_data = [];
          for (let index = 0; index <= elem1.m; index++) {
            const obj = {};
            const dm = new Date(Number(elem1.y), index, 1);
            const dp = new Date(Number(elem1.y), index + 1, 0);
            const data = await Readings.daySummary(cred.id, { start: dm.getTime(), end: dm.getTime() + 1000 * 60 * 60 * 24 * dp.getDate() });
            let sum = 0;
            for (let index = 0; index < data.length; index++) {
              const elem2 = data[index];
              sum += elem2.lines[0].kwht;
            }
            obj.date = dm;
            obj.kwht = sum;
            per_data.push(obj);
          }
          per_data2.push(per_data);
        }

        const py = { categories: [], series: [{ line: '0', prop: KWH, data: [] }] };
        const cy = { categories: [], series: [{ line: '0', prop: KWH, data: [] }] };
        const twoDates = [py, cy];
        for (let index = 0; index < twoDates.length; index++) {
          const dates = per_data2[index];
          if (dates.length > 0) {
            dates.forEach((date) => {
              twoDates[index].series.forEach((serie) => { serie.data.push({ x: date.date.getMonth() + 1, y: date.kwht }); });
              twoDates[index].categories.push(date.date);
            });
          }
        }

        const acnt = per_data2[0].length; const pcnt = per_data2[1].length;
        const maxDays = acnt > pcnt ? twoDates[0].categories : twoDates[1].categories;

        const mformat = { categories: [], series: [] }; mformat.categories = maxDays;
        const yr1 = twoDates[0].categories[0].getFullYear(); const yr2 = twoDates[1].categories[0].getFullYear();

        const amonths = []; twoDates[1].series[0].data.forEach((element) => { amonths.push({ x: element.x, y: element.y }); });
        const pmonths = []; twoDates[0].series[0].data.forEach((element) => { pmonths.push({ x: element.x, y: element.y }); });
        const aobj = { data: [], prop: yr2 }; const pobj = { data: [], prop: yr1 };
        mformat.series = [pobj, aobj];

        for (let index = 0; index < maxDays.length; index++) {
          const pdata = pmonths.find((e) => e.x === index + 1);
          const adata = amonths.find((e) => e.x === index + 1);
          pdata ? mformat.series[0].data.push(pdata) : mformat.series[0].data.push({ x: index + 1, y: null });
          adata ? mformat.series[1].data.push(adata) : mformat.series[1].data.push({ x: index + 1, y: null });
        }

        return mformat;
      }

      let t1_fmtd; let t2_fmtd; let resArr; const data_retrieved = { categories: [], series: [{ line: '0', prop: KWH, data: [] }] };
      if (mode === 'hour') {
        t1_fmtd = custom ? time[0].setHours(0, 0, 0, 0) : (time[0].getTime() - 1000 * 60 * 60 * 24);
        t2_fmtd = custom ? new Date(t1_fmtd).setHours(23, 0, 0, 0) : time[0].getTime();

        const hr24 = await Readings.byMeter({ id: cred.id, key: cred.key }, { end: t2_fmtd, start: t1_fmtd });
        if (hr24.data.length === 0) return;
        let initDate = new Date(hr24.data[0].timestamp);

        initDate.setMinutes(0);
        let valInit = new Date(hr24.data[0].timestamp).getHours(); let numsHrs = []; const hrsReg = []; const testArr = [];
        hr24.data.forEach((element) => {
          const date = new Date(element.timestamp);
          if (date.getHours() === valInit) { numsHrs.push(valInit); } else {
            hrsReg.push({ hr: valInit, qty: numsHrs.length, orDate: initDate.getTime() });
            valInit = date.getHours(); date.setMinutes(0); initDate = date;
            numsHrs = []; numsHrs.push(date.getHours());
          }
          testArr.push(element.lines[0].kwht);
        });
        const diffsArr = [];
        for (let index = 0; index < testArr.length - 1; index++) { const element = testArr[index + 1] - testArr[index]; diffsArr.push(element); }
        diffsArr.push(0);
        hrsReg.push({ hr: valInit, qty: numsHrs.length, orDate: initDate.getTime() });
        const helpArr = []; let helpAcc = 0;
        hrsReg.forEach((element) => { helpAcc += element.qty; helpArr.push(helpAcc); });
        let starter = 0; let cnt = 0; resArr = [];
        hrsReg.forEach((element) => {
          let acc = 0;
          for (let index = starter; index < helpArr[cnt]; index++) { acc += diffsArr[index]; }
          const obj = {
            lines: [{
              nline: 1, kwht: Number(acc.toFixed(2)), amps: 0, volts: 0, watts: 0,
            }],
            _id: { hour: element.hr, od: element.orDate },
          };
          resArr.push(obj); starter = helpArr[cnt]; cnt += 1;
        });
        const curr = resArr[resArr.length - 1];
        const od_curr = new Date(curr._id.od);
        od_curr.setHours(curr._id.hour + 1, 0);
        const obj = {
          lines: [{
            amps: 0, kwht: 0, nline: 1, volts: 0, watts: 0,
          }],
          _id: { hour: curr._id.hour + 1, od: od_curr.getTime() },
        };
        resArr.push(obj);
        resArr.forEach((reading) => {
          const dt = `${reading._id.hour}:00`;
          data_retrieved.series.forEach((serie) => { serie.data.push({ x: dt, y: reading.lines[serie.line][serie.prop].toFixed(2) }); });
          data_retrieved.categories.push(dt);
        });
        return data_retrieved;
      }
      const prevArray = []; const follArray = [];

      t2_fmtd = custom ? time[0].setHours(23, 59, 59, 999) : time[0].setHours(0, 0, 0, 0);
      t1_fmtd = t2_fmtd - 1000 * 60 * 60 * 24 * qty;

      const currDate = new Date(t1_fmtd); const currMonth = currDate.getMonth() + 1;
      const days = await Readings.daySummary(cred.id, { start: t1_fmtd, end: t2_fmtd });

      if (days.length === 0) return;

      for (let index = 0; index < days.length; index++) { const element = days[index]; if (days[index]._id.month === currMonth) { follArray.push(element); } else { prevArray.push(element); } }
      let c1 = 0; let c2 = 0;
      for (let i = 0; i < prevArray.length - 1; i++) { c1 += 1; if (prevArray[i]._id.day > prevArray[i + 1]._id.day) { for (let j = c1; j >= 1; j--) { if (prevArray[j]._id.day < prevArray[j - 1]._id.day) { const num = prevArray[j]; prevArray[j] = prevArray[j - 1]; prevArray[j - 1] = num; } } } }
      for (let i = 0; i < follArray.length - 1; i++) { c2 += 1; if (follArray[i]._id.day > follArray[i + 1]._id.day) { for (let j = c2; j >= 1; j--) { if (follArray[j]._id.day < follArray[j - 1]._id.day) { const num = follArray[j]; follArray[j] = follArray[j - 1]; follArray[j - 1] = num; } } } }
      const newArray = follArray.concat(prevArray);

      if (mode === 'week') {
        const arrLng = newArray.length; const numWeeks = Math.floor(arrLng / 7); const helper = [0]; let sum = 0; let c = 1; const arrWeeks = []; let hWeek = []; const hRem = []; const wArr = [];
        for (let index = 0; index < numWeeks; index++) { sum += 7; helper.push(sum); }
        const hLen = helper.length - 1; const datesWeek = [newArray[0]._id];
        for (let index = 0; index < arrLng; index++) {
          const element = newArray[index];
          if (index < helper[c]) { hWeek.push(element); } else { c += 1; if (index < helper[hLen]) { arrWeeks.push(hWeek); hWeek = []; hWeek.push(element); datesWeek.push(element._id); } else { hRem.push(element); } }
        }
        arrWeeks.push(hWeek);

        if (hRem.length > 0) { arrWeeks.push(hRem); datesWeek.push(hRem[0]._id); }
        const kwhs = []; const lastdates = [];
        arrWeeks.forEach((array) => {
          const qty = [];
          array.forEach((day, i) => { if (i === array.length - 1) { lastdates.push(day._id); } qty.push(day.lines[0].kwht); }); const sum = Number(qty.reduce((partialSum, a) => partialSum + a, 0).toFixed(2)); kwhs.push(sum);
        });
        for (let index = 0; index < datesWeek.length; index++) {
          const element = datesWeek[index]; const element2 = lastdates[index]; const obj = {
            lines: [{
              amps: 0, kwht: kwhs[index], nline: 1, volts: 0, watts: 0,
            }],
            _id: element,
            _id2: element2,
          }; wArr.push(obj);
        }
        wArr.forEach((reading, i) => {
          const dt = new Date(reading._id.year, reading._id.month - 1, reading._id.day);
          const dt2 = `${months[reading._id.month - 1]} ${reading._id.day}`;
          const dt3 = `${months[reading._id2.month - 1]} ${reading._id2.day}`;
          const tag = `${dt2} - ${dt3}`;
          data_retrieved.series.forEach((serie) => { serie.data.push({ x: tag, y: reading.lines[serie.line][serie.prop].toFixed(2) }); });
          data_retrieved.categories.push(dt.toLocaleDateString());
        });
        return data_retrieved;
      }
      newArray.forEach((reading) => {
        const dt = new Date(reading._id.year, reading._id.month - 1, reading._id.day);
        const dt2 = `${months[reading._id.month - 1]} ${reading._id.day}`;
        data_retrieved.series.forEach((serie) => { serie.data.push({ x: dt2, y: reading.lines[serie.line][serie.prop].toFixed(2) }); });
        data_retrieved.categories.push(dt);
      });
      return data_retrieved;
    },
    async getBarGraphData({ state }, data) {
      const {
        cred, time, dayMode, week, custom, date, timespan,
      } = data;
      let resArr = [];
      if (custom) {
        const currDate = date; const currMonth = currDate.getMonth() + 1; const prevArray = []; const follArray = [];
        for (let index = 0; index < time.length; index++) { const element = time[index]; if (time[index]._id.month === currMonth) { follArray.push(element); } else { prevArray.push(element); } }
        let c1 = 0; let c2 = 0;
        for (let i = 0; i < prevArray.length - 1; i++) { c1 += 1; if (prevArray[i]._id.day > prevArray[i + 1]._id.day) { for (let j = c1; j >= 1; j--) { if (prevArray[j]._id.day < prevArray[j - 1]._id.day) { const num = prevArray[j]; prevArray[j] = prevArray[j - 1]; prevArray[j - 1] = num; } } } }
        for (let i = 0; i < follArray.length - 1; i++) { c2 += 1; if (follArray[i]._id.day > follArray[i + 1]._id.day) { for (let j = c2; j >= 1; j--) { if (follArray[j]._id.day < follArray[j - 1]._id.day) { const num = follArray[j]; follArray[j] = follArray[j - 1]; follArray[j - 1] = num; } } } }
        const newArray = prevArray.concat(follArray);

        const array15 = []; const array7 = [];
        for (let index = newArray.length - 7; index < newArray.length; index++) if (newArray[index] != undefined) array7.push(newArray[index]);
        for (let index = newArray.length - 15; index < newArray.length; index++) if (newArray[index] != undefined) array15.push(newArray[index]);

        const arrLng = newArray.length; const numWeeks = Math.floor(arrLng / 7); const helper = [0]; let sum = 0; let c = 1; const arrWeeks = []; let hWeek = []; const hRem = [];
        for (let index = 0; index < numWeeks; index++) { sum += 7; helper.push(sum); }
        const hLen = helper.length - 1; const datesWeek = [newArray[0]._id];
        for (let index = 0; index < arrLng; index++) { const element = newArray[index]; if (index < helper[c]) { hWeek.push(element); } else { c += 1; if (index < helper[hLen]) { arrWeeks.push(hWeek); hWeek = []; hWeek.push(element); datesWeek.push(element._id); } else { hRem.push(element); } } }
        arrWeeks.push(hWeek);
        if (hRem.length > 0) {
          arrWeeks.push(hRem);
          datesWeek.push(hRem[0]._id);
        }
        const kwhs = []; const lastdates = [];
        arrWeeks.forEach((array) => { const qty = []; array.forEach((day, i) => { if (i === array.length - 1) { lastdates.push(day._id); } qty.push(day.lines[0].kwht); }); const sum = Number(qty.reduce((partialSum, a) => partialSum + a, 0).toFixed(2)); kwhs.push(sum); });
        const weekArr = []; for (let index = 0; index < datesWeek.length; index++) {
          const element = datesWeek[index]; const element2 = lastdates[index]; const obj = {
            lines: [{
              amps: 0, kwht: kwhs[index], nline: 1, volts: 0, watts: 0,
            }],
            _id: element,
            _id2: element2,
          }; weekArr.push(obj);
        }

        const date24 = date;
        date24.setHours(0, 0, 0, 0);
        const hr24 = await Readings.byMeter({ id: cred.id, key: cred.key }, { end: date24.getTime() + 1000 * 60 * 60 * 24, start: date24.getTime() });
        if (hr24.data.length === 0) return newArray;
        let initDate = new Date(hr24.data[0].timestamp);
        initDate.setMinutes(0);
        let valInit = new Date(hr24.data[0].timestamp).getHours(); let numsHrs = []; const hrsReg = []; const testArr = [];
        hr24.data.forEach((element) => {
          const date = new Date(element.timestamp);
          if (date.getHours() === valInit) {
            numsHrs.push(valInit);
          } else {
            hrsReg.push({ hr: valInit, qty: numsHrs.length, orDate: initDate.getTime() });
            valInit = date.getHours();
            date.setMinutes(0);
            initDate = date;
            numsHrs = []; numsHrs.push(date.getHours());
          }
          testArr.push(element.lines[0].kwht);
        });
        const diffsArr = []; const arr24 = [];
        for (let index = 0; index < testArr.length - 1; index++) { const element = testArr[index + 1] - testArr[index]; diffsArr.push(element); }
        diffsArr.push(0);
        hrsReg.push({ hr: valInit, qty: numsHrs.length, orDate: initDate.getTime() });
        const helpArr = []; let helpAcc = 0;
        hrsReg.forEach((element) => { helpAcc += element.qty; helpArr.push(helpAcc); });
        let starter = 0; let cnt = 0; resArr = [];
        hrsReg.forEach((element) => {
          let acc = 0;
          for (let index = starter; index < helpArr[cnt]; index++) { acc += diffsArr[index]; }
          const obj = {
            lines: [{
              nline: 1, kwht: Number(acc.toFixed(2)), amps: 0, volts: 0, watts: 0,
            }],
            _id: { hour: element.hr, od: element.orDate },
          };
          arr24.push(obj);
          starter = helpArr[cnt];
          cnt += 1;
        });
        switch (timespan) { case 0: return arr24; case 1: return array7; case 2: return array15; case 3: return newArray; case 4: return weekArr; default: break; }
      } else {
        if (dayMode) {
          if (week) {
            const arrLng = time.length; const numWeeks = Math.floor(arrLng / 7); const helper = [0]; let sum = 0; let c = 1; const arrWeeks = []; let hWeek = []; const hRem = [];
            for (let index = 0; index < numWeeks; index++) { sum += 7; helper.push(sum); }
            const hLen = helper.length - 1; const datesWeek = [time[0]._id];
            for (let index = 0; index < arrLng; index++) { const element = time[index]; if (index < helper[c]) { hWeek.push(element); } else { c += 1; if (index < helper[hLen]) { arrWeeks.push(hWeek); hWeek = []; hWeek.push(element); datesWeek.push(element._id); } else { hRem.push(element); } } }
            arrWeeks.push(hWeek); arrWeeks.push(hRem); datesWeek.push(hRem[0]._id);
            const kwhs = []; const lastdates = [];
            arrWeeks.forEach((array) => {
              const qty = [];
              array.forEach((day, i) => { if (i === array.length - 1) { lastdates.push(day._id); } qty.push(day.lines[0].kwht); }); const sum = Number(qty.reduce((partialSum, a) => partialSum + a, 0).toFixed(2)); kwhs.push(sum);
            });
            for (let index = 0; index < datesWeek.length; index++) {
              const element = datesWeek[index]; const element2 = lastdates[index]; const obj = {
                lines: [{
                  amps: 0, kwht: kwhs[index], nline: 1, volts: 0, watts: 0,
                }],
                _id: element,
                _id2: element2,
              }; resArr.push(obj);
            }
          } else {
            const currDate = new Date(); const currMonth = currDate.getMonth() + 1; const prevArray = []; const follArray = [];
            for (let index = 0; index < time.length; index++) { const element = time[index]; if (time[index]._id.month === currMonth) { follArray.push(element); } else { prevArray.push(element); } }
            let c1 = 0; let c2 = 0;
            for (let i = 0; i < prevArray.length - 1; i++) { c1 += 1; if (prevArray[i]._id.day > prevArray[i + 1]._id.day) { for (let j = c1; j >= 1; j--) { if (prevArray[j]._id.day < prevArray[j - 1]._id.day) { const num = prevArray[j]; prevArray[j] = prevArray[j - 1]; prevArray[j - 1] = num; } } } }
            for (let i = 0; i < follArray.length - 1; i++) { c2 += 1; if (follArray[i]._id.day > follArray[i + 1]._id.day) { for (let j = c2; j >= 1; j--) { if (follArray[j]._id.day < follArray[j - 1]._id.day) { const num = follArray[j]; follArray[j] = follArray[j - 1]; follArray[j - 1] = num; } } } }
            const newArray = prevArray.concat(follArray); const array15 = []; const array7 = [];
            for (let index = newArray.length - 7; index < newArray.length; index++) if (newArray[index] != undefined) array7.push(newArray[index]);
            for (let index = newArray.length - 15; index < newArray.length; index++) if (newArray[index] != undefined) array15.push(newArray[index]);
            resArr = [newArray, array7, array15];
          }
          return resArr;
        }
        const hr24 = await Readings.byMeter({ id: cred.id, key: cred.key }, { end: new Date().getTime(), start: new Date().getTime() - 1000 * 60 * 60 * time });

        if (hr24.data.length === 0) return resArr;
        let initDate = new Date(hr24.data[0].timestamp);
        initDate.setMinutes(0);
        let valInit = new Date(hr24.data[0].timestamp).getHours(); let numsHrs = []; const hrsReg = []; const testArr = [];
        hr24.data.forEach((element) => {
          const date = new Date(element.timestamp);
          if (date.getHours() === valInit) {
            numsHrs.push(valInit);
          } else {
            hrsReg.push({ hr: valInit, qty: numsHrs.length, orDate: initDate.getTime() });
            valInit = date.getHours();
            date.setMinutes(0);
            initDate = date;
            numsHrs = []; numsHrs.push(date.getHours());
          }
          testArr.push(element.lines[0].kwht);
        });
        const diffsArr = [];
        for (let index = 0; index < testArr.length - 1; index++) { const element = testArr[index + 1] - testArr[index]; diffsArr.push(element); }
        diffsArr.push(0);
        hrsReg.push({ hr: valInit, qty: numsHrs.length, orDate: initDate.getTime() });
        const helpArr = []; let helpAcc = 0;
        hrsReg.forEach((element) => { helpAcc += element.qty; helpArr.push(helpAcc); });
        let starter = 0; let cnt = 0; resArr = [];
        hrsReg.forEach((element) => {
          let acc = 0;
          for (let index = starter; index < helpArr[cnt]; index++) { acc += diffsArr[index]; }
          const obj = {
            lines: [{
              nline: 1, kwht: Number(acc.toFixed(2)), amps: 0, volts: 0, watts: 0,
            }],
            _id: { hour: element.hr, od: element.orDate },
          };
          resArr.push(obj);
          starter = helpArr[cnt];
          cnt += 1;
        });

        const curr = resArr[resArr.length - 1];
        const od_curr = new Date(curr._id.od);
        od_curr.setHours(curr._id.hour + 1, 0);

        const obj = {
          lines: [{
            amps: 0, kwht: 0, nline: 1, volts: 0, watts: 0,
          }],
          _id: {
            hour: curr._id.hour + 1,
            od: od_curr.getTime(),
          },
        };
        resArr.push(obj);
        return resArr;
      }
    },
    async getComparativeBarGraphData({ state }, data) {
      const {
        cred, time, modDays, modMonths,
      } = data;
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      if (modMonths) {
        let yn = new Date(); yn = yn.getFullYear();
        const cydata = []; const pydata = []; let sum;
        for (let index = 0; index < time[0].length; index++) {
          const element = time[0][index];
          const data = await Readings.daySummary(cred.id, { start: element.date.getTime(), end: element.date.getTime() + 1000 * 60 * 60 * 24 * element.qty });
          if (data.length > 0) {
            sum = 0; for (let index2 = 0; index2 < data.length; index2++) { const element = data[index2]; sum += element.lines[0].kwht; }
          } else { sum = 0; }
          const obj = { date: element.date, value: sum };
          if (element.date.getFullYear() === yn) { cydata.push(obj); } else { pydata.push(obj); }
        }
        return [{ data: cydata, year: yn.toString() }, { data: pydata, year: (yn - 1).toString() }];
      }
      if (modDays) {
        if (time.length === 1) {
          const currMonth = time[0].getMonth() + 1;
          const actMonth = currMonth;
          const actYear = time[0].getFullYear();
          let prevMonth; let prevYear;
          if (actMonth > 1) { prevMonth = currMonth - 1; prevYear = actYear; } else { prevMonth = 12; prevYear = actYear - 1; }
          const periods = [{ year: actYear, month: actMonth }, { year: prevYear, month: prevMonth }];

          const per_data = [];
          for (let i = 0; i < periods.length; i++) {
            const elem = periods[i];
            const m = new Date(elem.year, elem.month - 1, 1);
            const cnt = new Date(elem.year, elem.month, 0).getDate();
            const data = await Readings.daySummary(cred.id, { start: m.getTime(), end: m.getTime() + 1000 * 60 * 60 * 24 * cnt });
            let c = 0;
            for (let j = 0; j < data.length - 1; j++) {
              c += 1;
              if (data[j]._id.day > data[j + 1]._id.day) {
                for (let k = c; k >= 1; k--) {
                  if (data[k]._id.day < data[k - 1]._id.day) {
                    const num = data[k];
                    data[k] = data[k - 1];
                    data[k - 1] = num;
                  }
                }
              }
            }
            per_data.push(data);
          }
          return per_data;
        } if (time.length > 1) {
          const per_data = [];
          for (let index = 0; index < time.length; index++) {
            const elem = time[index];
            const [y, m] = elem.split('-');
            const y_date = Number(y);
            const m_date = Number(m);
            const date = new Date(y_date, m_date - 1, 1);
            const cnt = new Date(y_date, m_date, 0).getDate();
            const data = await Readings.daySummary(cred.id, { start: date.getTime(), end: date.getTime() + 1000 * 60 * 60 * 24 * cnt });
            let c = 0;
            for (let j = 0; j < data.length - 1; j++) {
              c += 1;
              if (data[j]._id.day > data[j + 1]._id.day) {
                for (let k = c; k >= 1; k--) {
                  if (data[k]._id.day < data[k - 1]._id.day) {
                    const num = data[k];
                    data[k] = data[k - 1];
                    data[k - 1] = num;
                  }
                }
              }
            }
            per_data.push(data);
          }
          return per_data;
        }
      } else {
        if (time.length === 1) {
          let t = time[0].setHours(0, 0, 0, 0);
          const pres = await Readings.byMeter({ id: cred.id, key: cred.key }, { end: t + 1000 * 60 * 60 * 24, start: t });
          const past = await Readings.byMeter({ id: cred.id, key: cred.key }, { end: t, start: t - 1000 * 60 * 60 * 24 });
          let y = new Date();

          if (past.data[0].length > 0) {
            y = new Date(past.data[0].timestamp);
          } else {
            const y2 = new Date(y);
            y = y2.setDate(y2.getDate() - 1);
          }

          const arrDates = [t, y]; const arrData = [pres, past]; const finalRes = [];
          for (let index = 0; index < arrDates.length; index++) {
            const elem = arrDates[index];
            const tf = moment(elem).format('L');
            const [tm, td] = tf.split('/');
            const month_t = Number(tm);
            const day_t = Number(td);
            const t_prop = `${months[month_t - 1]} ${day_t}`;
            if (arrData[index].data.length > 1) {
              let t_valInit = new Date(arrData[index].data[0].timestamp).getHours(); let t_numsHrs = []; const t_hrsReg = []; const t_testArr = [];
              arrData[index].data.forEach((element) => {
                const t_date = new Date(element.timestamp);
                if (t_date.getHours() === t_valInit) {
                  t_numsHrs.push(t_valInit);
                } else {
                  t_hrsReg.push({ hr: t_valInit, qty: t_numsHrs.length, orDate: t });
                  t_valInit = t_date.getHours();
                  t = t_date;
                  t_numsHrs = []; t_numsHrs.push(t_date.getHours());
                }
                t_testArr.push(element.lines[0].kwht);
              });
              const t_diffsArr = [];
              for (let index = 0; index < t_testArr.length - 1; index++) { const element = t_testArr[index + 1] - t_testArr[index]; t_diffsArr.push(element); }
              t_diffsArr.push(0);
              t_hrsReg.push({ hr: t_valInit, qty: t_numsHrs.length, orDate: typeof t === 'number' ? t : t.getTime() });
              const t_helpArr = []; let t_helpAcc = 0;
              t_hrsReg.forEach((element) => { t_helpAcc += element.qty; t_helpArr.push(t_helpAcc); });
              let t_starter = 0; let t_cnt = 0; const t_resArr = [];
              t_hrsReg.forEach((element) => {
                let acc = 0;
                for (let index = t_starter; index < t_helpArr[t_cnt]; index++) { acc += t_diffsArr[index]; }
                const obj = {
                  lines: [{
                    nline: 1, kwht: Number(acc.toFixed(2)), amps: 0, volts: 0, watts: 0,
                  }],
                  _id: { hour: element.hr, od: element.orDate },
                };
                t_resArr.push(obj);
                t_starter = t_helpArr[t_cnt];
                t_cnt += 1;
              });
              const resObj = { prop: t_prop, data: t_resArr };
              finalRes.push(resObj);
            }
          }
          return finalRes;
        } if (time.length > 1) {
          time[0].setHours(0, 0); time[1].setHours(0, 0);
          let date1 = await Readings.byMeter({ id: cred.id, key: cred.key }, { end: time[0].getTime() + 1000 * 60 * 60 * 24, start: time[0].getTime() });
          let date2 = await Readings.byMeter({ id: cred.id, key: cred.key }, { end: time[1].getTime() + 1000 * 60 * 60 * 24, start: time[1].getTime() });

          date1 = (date1 === undefined || date1 === null) ? { data: [] } : date1;
          date2 = (date2 === undefined || date2 === null) ? { data: [] } : date2;
          date1.data = (date1.data === undefined || date1.data === null) ? [] : date1.data;
          date2.data = (date2.data === undefined || date2.data === null) ? [] : date2.data;

          const arrDates = time; const arrData = [date1, date2]; const finalRes = [];
          for (let index = 0; index < arrDates.length; index++) {
            const elem = arrDates[index];
            const tf = moment(elem).format('L'); const [tm, td] = tf.split('/');
            const month_t = Number(tm); const day_t = Number(td);
            const t_prop = `${months[month_t - 1]} ${day_t}`;
            if (arrData[index].data.length > 0) {
              let t_valInit = new Date(arrData[index].data[0].timestamp).getHours(); let t_numsHrs = []; const t_hrsReg = []; const t_testArr = [];
              arrData[index].data.forEach((element) => {
                const t_date = new Date(element.timestamp);
                if (t_date.getHours() === t_valInit) {
                  t_numsHrs.push(t_valInit);
                } else {
                  t_hrsReg.push({ hr: t_valInit, qty: t_numsHrs.length, orDate: time[index] });
                  t_valInit = t_date.getHours(); t_numsHrs = []; t_numsHrs.push(t_date.getHours());
                }
                t_testArr.push(element.lines[0].kwht);
              });
              const t_diffsArr = [];
              for (let index = 0; index < t_testArr.length - 1; index++) { const element = t_testArr[index + 1] - t_testArr[index]; t_diffsArr.push(element); }
              t_diffsArr.push(0);
              t_hrsReg.push({ hr: t_valInit, qty: t_numsHrs.length, orDate: time[index].getTime() });
              const t_helpArr = []; let t_helpAcc = 0;
              t_hrsReg.forEach((element) => { t_helpAcc += element.qty; t_helpArr.push(t_helpAcc); });
              let t_starter = 0; let t_cnt = 0; const t_resArr = [];
              t_hrsReg.forEach((element) => {
                let acc = 0;
                for (let index = t_starter; index < t_helpArr[t_cnt]; index++) { acc += t_diffsArr[index]; }
                const obj = {
                  lines: [{
                    nline: 1, kwht: Number(acc.toFixed(2)), amps: 0, volts: 0, watts: 0,
                  }],
                  _id: { hour: element.hr, od: element.orDate },
                };
                t_resArr.push(obj);
                t_starter = t_helpArr[t_cnt];
                t_cnt += 1;
              });
              const resObj = { prop: t_prop, data: t_resArr };
              finalRes.push(resObj);
            } else {
              const resObj = { prop: t_prop, data: [] };
              finalRes.push(resObj);
            }
          }
          return finalRes;
        }
      }
    },
    request_nodes({ state, commit, dispatch }, query_params = null) {
      state.nodes_loader = true;
      return nodes_service.get_nodes(query_params)
        .then((nodes) => {
          commit('SET_NODES', nodes.Items);
          state.nodes_loader = false;
          return nodes.Items;
        })
        .catch((err) => {
          state.nodes_loader = false;
          console.error(err);
        });
    },
    request_zones({ state, commit, dispatch }, query_params = null) {
      state.zones_loader = true;
      return zones_service.get_zones(query_params)
        .then((zones) => {
          commit('SET_ZONES', zones);
          state.zones_loader = false;
          return zones;
        })
        .catch((err) => {
          console.error(err);
          state.zones_loader = false;
        });
    },
    async fetchLogs({ state, rootState }, data) {

      try {
      
        const ZONES_TYPE = 'zones'
        let logsFromApi;
        const logMessages = [];
        const userData = JSON.parse(localStorage.getItem('user'))
        const info = await db.doc(`${state.collection ?? `usersGreenweb/${userData.uid}`}`).get();
        const date = new Date();
        const offset = moment.parseZone(date).utcOffset();

        if (data) {
          const { mode, value } = data;
          if (mode === 'targets') {
            logsFromApi = await Logger.getTarget(value);
          } else if (mode === 'schedule') {
            const gateway = rootState.gateways.mainGateway
            logsFromApi = await Logger.getSchedules(value, gateway);
          } else {
            logsFromApi = await Logger.getQty(value);
          }
        } else {
          logsFromApi = await Logger.get();
        }

        if (logsFromApi.data.logs) {        

          logsFromApi.data.logs.forEach(log => {
            
            let messageForLog;
            const date = new Date(log.closed);
            const timeInMilis = date.getTime();
            const logStatus = log.status;
            const functionInLog = log.data.messages[0].function;
            const targetsArray = [...log.data.targets]
  
            let mainTargetType = log.targetsType === ZONES_TYPE ? 'Zone' : 'Device';
            mainTargetType = log.data.targets.length > 1 ? `${mainTargetType}s` : mainTargetType;
  
            const targetsFormatted = log.targetsType === ZONES_TYPE 
                                                                    ? targetsArray.map(target => {
                                                                        const zoneTargeted = rootState.zones.zonesArray.find(zone => zone.id === target);
                                                                        return zoneTargeted?.name ?? target
                                                                      }) 
                                                                    : targetsArray
                                                                  
            const targetsChain = targetsFormatted.join(', ')
            const targetsForMessage = `${mainTargetType} ${targetsChain}`
            
            if (functionInLog === 'MotionSensor') {
            
              const minimumLevel = log.data.messages[1].data.minLevelConfiguration;
              const maximumLevel = log.data.messages[1].data.maxLevelConfiguration;
              messageForLog = [`${targetsForMessage} configured for automatic mode`, `Min illumination level: ${minimumLevel} - Max illumination level: ${maximumLevel}`]
  
            } else if (functionInLog === 'SmartActuator') {
  
              const illuminationLevel = log.data.messages[0].data.targetLightCommand;
              messageForLog = [`${targetsForMessage} configured for manual mode`, `Illumination level: ${illuminationLevel}`]
              
            } else if (functionInLog === 'CommandSender') {            
               
              if(log.data.messages[0].hasOwnProperty('commands')){
                log.data.messages[0].data = { commands: [] }
                log.data.messages[0].data.commands = log.data.messages[0].commands
                delete log.data.messages[0]['commands']
              }
  
              const operationMode = log.data.messages[0].data.commands.length > 1 ? 'automatic mode' : 'manual mode'; 
  
              const messageInfo = log.data.messages[0].data.commands.length > 1 
                                                                                ? {
                                                                                    minimumLevel: log.data.messages[0].data.commands[1].data.minLevelConfiguration, 
                                                                                    maximumLevel: log.data.messages[0].data.commands[1].data.maxLevelConfiguration
                                                                                  } 
                                                                                : {
                                                                                    illuminationLevel: log.data.messages[0].data.commands[0].data.targetLightCommand 
                                                                                  };
  
              const messageConfiguration = log.data.messages[0].data.commands.length > 1 
                                                                                        ? `Min illumination level: ${messageInfo.minimumLevel} - Max illumination level: ${messageInfo.maximumLevel}` 
                                                                                        : `Illumination level: ${messageInfo.illuminationLevel}` 
  
              messageForLog = [`${targetsForMessage} configured for ${operationMode}`, `${messageConfiguration}`];
            
            } else if (functionInLog === 'ZoneNode') {
  
              const newZone = rootState.zones.zonesArray.find(zone => zone.id === log.data.messages[0].data.zoneID);
              messageForLog = [`${targetsForMessage} moved to zone ${newZone ? newZone.name : log.data.messages[0].data.zoneID}`]
  
            } else if (functionInLog === 'ScheduleConfigurator') {
              
              const scheduleID = log.data.messages[0].data.id;
              const scheduleSelected = state.schedules.find(schedule => schedule.schedule_id === scheduleID);
  
              const deletedSchedulesArray = info.data().deleted_schedules;
              const deleteScheduleSelected = deletedSchedulesArray.find(schedule => schedule.id === scheduleID);
              const scheduleName = scheduleSelected 
                                                    ? scheduleSelected.name 
                                                    : deleteScheduleSelected 
                                                                            ? deleteScheduleSelected.name 
                                                                            : scheduleID;
  
              if (log.data.messages[0].delete === true) {              
                messageForLog = [`Schedule ${scheduleName} deleted`]
              } else if (log.data.messages[0].active === true) {              
                const objectSize = Object.keys(log.data.messages[0].data).length;
                
                if (objectSize > 1) {
                  const { days, time } = log.data.messages[0].data;
                  const [ hour, minutes ] = time.split(':');
                  let hourFromLog = Number(hour) + (offset / 60);           
                  if (hourFromLog < 0) hourFromLog += 24;
                  const formattedHour = hourFromLog < 10 ? `0${hourFromLog}:${minutes}` : `${hourFromLog}:${minutes}`;
                  const timeInfo = `Days: ${days} - Time: ${formattedHour}`

                  const parametersInfo = log.data.messages[0].data.commands.length > 1 
                                                                                    ? {
                                                                                        minimumLevel: log.data.messages[0].data.commands[0].data.minLevelConfiguration,
                                                                                        maximumLevel: log.data.messages[0].data.commands[0].data.maxLevelConfiguration
                                                                                      } 
                                                                                    : {
                                                                                        illuminationLevel: log.data.messages[0].data.commands[0].data.targetLightCommand
                                                                                      }
  
                  const controlMode = log.data.messages[0].data.commands.length > 1 ? 'automatic mode' : 'manual mode'
                  const configurationMessage = log.data.messages[0].data.commands.length > 1 
                                                                                            ? `Min illumination level: ${parametersInfo.minimumLevel} - Max illumination level: ${parametersInfo.maximumLevel}` 
                                                                                            : `Illumination level: ${parametersInfo.illuminationLevel}`
  
                  messageForLog = [`Schedule ${scheduleName} configured for ${controlMode}`, `${configurationMessage} - ${timeInfo}`, `${targetsForMessage}`]
                } else {
                  messageForLog = [`Schedule ${scheduleName} activated`];
                }
              } else if (log.data.messages[0].active === false) {
                messageForLog = [`Schedule ${scheduleName} deactivated`];
              }
            } else {
              messageForLog = ['Unknown command'];
            }
  
            logMessages.push({
              date: log.created,
              type: log.targetsType ?? 'schedule',
              time_ms: timeInMilis,
              message: messageForLog,
              targets: log.data.targets,
              status: logStatus,
            });
          
          });
  
        } else {
          
          logsFromApi.data.forEach(log => {       
          
            const date = new Date(log.timestamp);
            const verbForText = log.data.targets.length > 1 ? 'have' : 'has';
          
            let mainTargetType = log.data.targetsType === ZONES_TYPE ? 'Zone' : 'Device';
            mainTargetType = log.data.targets.length > 1 ? `${mainTargetType}s` : mainTargetType;          
          
            const targetsToFormat = [...log.data.targets]
            const targetsFormatted = log.data.targetsType === ZONES_TYPE 
                                                                        ? targetsToFormat.map(target => {
                                                                            const targetZone = rootState.zones.zonesArray.find(zone => zone.id === target);
                                                                            return targetZone.name
                                                                          })
                                                                        : targetsToFormat
  
            const targetsChain = targetsFormatted.join(', ')
  
            const mode = log.data.commands.find((m) => m.data.hasOwnProperty('controlModeType'));
          
            if (!mode) return
  
            const targetsText = `${mainTargetType} ${targetsChain} ${verbForText}`;
            const fullText = mode.data.controlModeType === 0 
                                                            ? mode.data.targetLightCommand === 0 
                                                                                                ? [`${targetsText} been turned off`] 
                                                                                                : [`${targetsText} been turned on at level ${mode.data.targetLightCommand}`]
                                                            : [`${targetsText} been configured automatically from level ${mode.data.minLevelConfiguration} to level ${mode.data.maxLevelConfiguration}`];
  
            logMessages.push({
              date: log.timestamp, 
              time_ms: date.getTime(), 
              message: fullText, 
              targets: log.data.targets, 
              status: 'RECEIVED'
            });
      
          });      
        }
        return logMessages;
        
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    UPDATE_COLLECTION(state, path) {
      path
        ? state.collection = `usersGreenweb/${path}`
        : state.collection = null;
    },
    SET_NODES(state, nodes) {
      state.nodes = nodes.map(node => node.functions.Basic.assetId)
    },
    SET_ZONES(state, zones) {
      state.zones = zones;
    },
    SET_ZONE_ID(state, zoneId){
      state.zoneIdx = zoneId
    },
    SET_GROUPS(state, groups){
      state.groups = groups
    },
    SET_COLORS(state, colors){
      state.colors = colors
    },
    SET_LIGHTS_REGISTERED(state, lightsRegistered){
      state.lightsRegistered = lightsRegistered
    },
    SET_TERMINATION_DATE(state, terminationDate){
      localStorage.setItem('tdate', JSON.stringify(terminationDate));
      state.terminationDate = terminationDate
    },
    SET_COST(state, cost){
      localStorage.setItem('cost', JSON.stringify(cost));
      state.cost = cost
    },
    SET_MONTHLY_COST(state, monthlyCost){
      localStorage.setItem('cost_month', JSON.stringify(monthlyCost));
      state.monthlyCost = monthlyCost
    },
    SET_LAYOUT_ID(state, layoutId){
      state.layoutIdx = layoutId;
    },
    SET_LAYOUTS(state, layouts){
      state.layouts = layouts
    },
    SET_METERS(state, meters){
      state.meters = meters
    },
    SET_SCHEDULE_ID(state, scheduleId){
      state.scheduleIdx = scheduleId
    },
    SET_SCHEDULES(state, schedules){
      state.schedules = schedules
    },
    SET_LED_LAMP(state, ledLamp){
      state.ledLamp = ledLamp
    },
    SET_OLD_LAMP(state, oldLamp){
      state.oldLamp = oldLamp
    },
    SET_CAPACITY_DT(state, capacity) {
      state.capacityDT = capacity;
    },
    SET_LAYOUT_METERS(state, infoLayout){
      state.layoutMeter = infoLayout
    },
    SET_LAYOUT_ONELINE(state, infoLayout){
      state.layoutOneLine = infoLayout
    },
    ADD_SCHEDULE(state, data) {
      state.scheduleIdx = JSON.parse(localStorage.getItem('schedule_idx'));
      const { schedules } = state;
      schedules.push(data);
      localStorage.setItem('schedules', JSON.stringify(schedules));
      state.schedules = JSON.parse(localStorage.getItem('schedules'));
    },
    EDIT_SCHEDULE(state, data) {
      localStorage.setItem('schedules', JSON.stringify(data));
      state.schedules = data;
    },
    DELETE_SCHEDULE(state, data) {
      const d = state.schedules;
      d.splice(data.scheduleIdx, 1);
      localStorage.setItem('schedules', JSON.stringify(d));
      state.schedules = JSON.parse(localStorage.getItem('schedules'));
    },
    ADD_LAYOUT(state, data) {
      state.layoutIdx = JSON.parse(localStorage.getItem('layout_idx'));
      const { layouts } = state;
      layouts.push(data);
      localStorage.setItem('layouts', JSON.stringify(layouts));
      state.schedules = JSON.parse(localStorage.getItem('layouts'));
    },
    ADD_METER(state, data) {
      const meters = JSON.parse(localStorage.getItem('meters'));
      meters.push(data);
      localStorage.setItem('meters', JSON.stringify(meters));
      state.meters = JSON.parse(localStorage.getItem('meters'));
    },
    UPDATE_TERMINATION_DATE(state, date) {
      localStorage.setItem('tdate', JSON.stringify(date));
      state.terminationDate = date;
    },
    UPDATE_COST(state, cost) {
      localStorage.setItem('cost', JSON.stringify(cost));
      state.cost = cost;
    },
    ADD_REG_LIGHT(state, light) {
      const lightsRegisteredArray = JSON.parse(localStorage.getItem('regLights'));
      lightsRegisteredArray.push(light);
      state.lightsRegistered = lightsRegisteredArray;
      localStorage.setItem('regLights', JSON.stringify(lightsRegisteredArray));
    },
    DEL_REG_LIGHT(state, lightID) {
      const lightsRegisteredArray = JSON.parse(localStorage.getItem('regLights'));
      state.lightsRegistered = lightsRegisteredArray.filter(light => light.id !== lightID);
      localStorage.setItem('regLights', JSON.stringify(lightsRegisteredArray));
    },
  },

};
