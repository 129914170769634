import { apiWithProject } from './greenetworkAPI';
const ZONES = 'zones'
const END_POINT = '/gateways';

function getZonesMessageStructure(params){
  return params.A 
                ? [
                    {
                      function: 'CommandSender',
                      data: {
                        commands: [
                          {
                            function: 'MotionSensor',
                            data: {
                              motionSensorDistance: params.D,
                              motionSensorWaitTime: params.T,
                            },
                          },
                          {
                            function: 'SmartActuator',
                            data: {
                              maxLevelConfiguration: params.LH,
                              minLevelConfiguration: params.LL,
                              controlModeType: 1,
                            },
                          }
                        ]
                      }
                    }
                  ] 
                : [
                    {
                      function: 'CommandSender',
                      data: {
                        commands: [
                          {
                            function: 'SmartActuator',
                            data: {
                              targetLightCommand: params.LV,
                              controlModeType: 0,
                            }
                          }
                        ]
                      }
                    }                    
                  ]
}

function getDevicesMessageStructure(params){
  return params.A 
                ? [
                    {
                      function: 'MotionSensor',
                      data: {
                        motionSensorDistance: params.D,
                        motionSensorWaitTime: params.T,
                      },
                    },
                    {
                      function: 'SmartActuator',
                      data: {
                        maxLevelConfiguration: params.LH,
                        minLevelConfiguration: params.LL,
                        controlModeType: 1,
                      },
                    }
                  ] 
                : [
                    {
                      function: 'SmartActuator',
                      data: {
                        targetLightCommand: params.LV,
                        controlModeType: 0,
                      },
                    }
                  ]
}

function getScheduleConfigurationMessage(params){
  return [
          {
            function: 'ScheduleConfigurator',
            data: {
              id: params.ID,
              time: params.HR,
              days: params.WD,
              commands: params.A 
                                ? [
                                    {
                                      function: 'SmartActuator',
                                      data: {
                                        maxLevelConfiguration: params.LH,
                                        minLevelConfiguration: params.LL,
                                        controlModeType: 1,
                                      },
                                    },
                                    {
                                      function: 'MotionSensor',
                                      data: {
                                        motionSensorDistance: params.D,
                                        motionSensorWaitTime: params.T,
                                      },
                                    },
                                  ] 
                                : [
                                    {
                                      function: 'SmartActuator',
                                      data: {
                                        targetLightCommand: params.LV,
                                        controlModeType: 0,
                                      },
                                    }
                                  ]
            },
            active: params.E,
            delete: false,
          }
        ] 
}

const get = () => apiWithProject.get(`${END_POINT}`);

const post = (key, name_eui, name) => apiWithProject.post(`${END_POINT}`, {
  key,
  name_eui,
  name,
});

const assign = (name_eui) => apiWithProject.post(`${END_POINT}/main/${name_eui}` );

const deletion = (name_eui) => apiWithProject.delete(`${END_POINT}/${name_eui}`);

const deleteMainGateway = () => apiWithProject.delete(`${END_POINT}/main`);

const sendInstructions = (components, componentType, params, gateway) =>  apiWithProject.post(`communication/downlink/${gateway}`, {
  class: 'Neurolink',
  targets: components,
  targetType: componentType,
  messages: componentType === ZONES 
                                  ? getZonesMessageStructure(params) 
                                  : getDevicesMessageStructure(params),
  ack: true
}).then((res) => res)
  .catch((err) => { console.log(err); });

const sendScheduleConfiguration = (zones, params, gateway) => apiWithProject.post(`communication/downlink/${gateway}/zones`, {
  targets: zones,
  class: 'Neurolink',
  targetType: 'zones',
  messages: getScheduleConfigurationMessage(params),
  ack: true,
}).then((res) => res)
.catch((err) => { console.log(err); });

const scheduleEnabling = (zones, scheduleID, enable, gateway) => apiWithProject.post(`communication/downlink/${gateway}/zones`, {
  targets: zones,
  class: 'Neurolink',
  targetType: 'zones',
  messages: [
    {
      function: 'ScheduleConfigurator',
      data: {
        id: scheduleID,
      },
      active: enable,
    },
  ],
  ack: true,
}).then((res) => res)
  .catch((err) => { console.log(err); });

const scheduleDeletion = (zones, scheduleID, gateway) => apiWithProject.post(`communication/downlink/${gateway}/zones`, {
  targets: zones,
  class: 'Neurolink',
  targetType: 'zones',
  messages: [
    {
      function: 'ScheduleConfigurator',
      data: {
        id: scheduleID,
      },
      delete: true,
    },
  ],
  ack: true,
}).then((res) => res)
  .catch((err) => { console.log(err); });

const zoneSet = (zone, nodes, gateway) => apiWithProject.post(`communication/downlink/${gateway}/zones`, {
  targets: nodes,
  class: 'Neurolink',
  targetType: 'devices',
  messages: [
    {
      function: 'ZoneNode',
      data: {
        zoneID: zone,
      },
    },
  ],
  ack: true,
}).then((res) => res)
  .catch((err) => { console.log(err); });

const Gateway = {
  get, post, assign, deletion, sendScheduleConfiguration,
  deleteMainGateway, zoneSet, scheduleDeletion, scheduleEnabling, sendInstructions
};

export default Gateway;
