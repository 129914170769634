<template>
  <v-bottom-sheet
    v-model="bottomSheetEnable"
    inset
  >
    <!--<template v-slot:activator="{ on, attrs }">
      <v-btn
        color="orange"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Open Inset
      </v-btn>
    </template>-->
    <v-sheet
      class="text-center"
      height="400px"
    >
      <v-btn
        class="mt-6"
        text
        color="error"
        @click="setBottomSheet(!bottomSheetEnable)"
      >
        close
      </v-btn>

      <CommandLogger/>

    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CommandLogger from '@/components/CommandLogger';

export default {
  name: 'BottomSheet',
  components: { CommandLogger },
  data: () => ({
  }),
  methods: {
    ...mapMutations({
      setBottomSheet: 'app/SET_BOTTOM_SHEET_VISIBLE',
    }),
    ...mapGetters({
      getBottomSheet: 'app/get_bottom_sheet',
    }),
  },
  computed: {
    bottomSheetEnable: {
      get() {
        return this.$store.state.app.bottomSheet;
      },
      set(val) {
        this.setBottomSheet(val);
      },
    },
  },
};
</script>

<style scoped>

</style>
