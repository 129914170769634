<template>
  <v-container>
  <v-card shaped raised>
    <v-card-title>Logger</v-card-title>
  <v-list
    style="max-height: 400px"
    class="overflow-y-auto"
  >
    <v-list-item
      v-if="item in items" v-for="(item, index) in items.slice().reverse()"
      :key="item.message.meta.timestamp"
      @click="()=>{}"
    >
      <v-list-item-content>
        <v-list-item-title> {{ item.message.data.params | format_params }}</v-list-item-title>
        <v-list-item-subtitle>{{ item.timestamp | format_date }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-icon>
        <v-progress-circular v-if="item.pending" :indeterminate="item.pending" color="red" size="23"/>
        <v-icon v-if="!item.pending" :color="(item.ack_result) ? 'green': 'red'">
          {{ (item.ack_result) ? 'mdi-checkbox-multiple-marked-circle' : 'mdi-alert-outline' }}
        </v-icon>
      </v-list-item-icon>
    </v-list-item>
  </v-list>
  </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
// import moment from 'moment/src/moment';
import moment from 'moment';
import { ParamInfo } from '@/messages/api';

export default {
  name: 'CommandLogger',
  data() {
    return {

    };
  },
  methods: {
    npm(value) {
      console.log(value);
    },
  },
  computed: {
    ...mapGetters({
      items: 'messages/get_messages',
    }),
  },
  filters: {
    format_date: (value) => {
      if (value) { return moment(value).format('M/DD/YY HH:mm:ss'); }
    },
    format_params: (params) =>
    // params it's an object
      Object.keys(params).flatMap((param, index) => `${ParamInfo[param].name} : ${params[param]}`).join(' '),

  },
};
</script>

<style scoped>

</style>
