<template>
  <div>
    <v-dialog v-model="open" persistent width="650px">
      <v-card style="overflow: hidden;">
        <v-card-title>{{ editMode ? 'Edit' : 'Add' }} group
          <v-spacer/>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form>
          <v-row class="pa-4">
            <v-col cols="12">
              <v-text-field v-model="groupName" outlined dense label="Name" />
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-textarea v-model="description" label="Description" outlined rows="3" dense/>
            </v-col>
            <v-col cols="12" class="mt-n3">
              <div align="center">
                <v-autocomplete 
                  v-model="metersSelected" 
                  :items="meterList" 
                  item-text="name" 
                  item-value="name" 
                  outlined 
                  dense 
                  chips 
                  color="blue-grey lighten-2" 
                  label="Select meters..." 
                  multiple 
                  flat 
                  return-object
                  hint="Select at least two meters."
                  persistent-hint
                >
                  <template v-slot:selection="data">
                    <v-chip 
                      v-bind="data.attrs" 
                      :input-value="data.item" 
                      close 
                      @click="data" 
                      @click:close="remove(data.item)"
                    >{{ data.item.name }}</v-chip>
                  </template>
                </v-autocomplete>
              </div>
            </v-col>   
            <v-col cols="12" class="mt-n5">
              <v-card-actions>
                <v-spacer/>
                <transition v-if="error" name="shake">
                  <p class="shake red--text mb-1 mr-3">
                    {{ errMsg }}
                  </p>
                </transition>
                <v-btn 
                  color="primary"
                  :loading="loading"
                  @click="saveGroup(groupName, metersSelected, description, groupID)"
                  :disabled="groupName === '' || metersSelected.length < 2 || description === ''"
                >Save</v-btn>
                <v-btn
                  v-if="editMode"
                  color="red"
                  :disabled="loading"
                  @click="openDeleteDialog = true"
                >Delete</v-btn>
              </v-card-actions>
            </v-col>         
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      elevation="0" 
      v-model="msgSuccess" 
      max-width="350px" 
      style="overflow: hidden"
    >
      <v-card 
        elevation="0" 
        color="#323232" 
        flat 
        class="pl-4 pr-n1" 
        height="55px" 
        width="350px" 
        style="overflow: hidden; position: absolute; bottom: 0;"
      >
        <v-row>
          <v-col 
            cols="8" 
            class="mt-1"
          >
            <p 
              class="white--text mt-3" 
              style="font-size: 14px"
            >Group {{ editMode ? 'edited' : 'added'}} successfully</p>
          </v-col>
          <v-col 
            cols="4"
          >
            <v-btn 
              class="ml-2 mt-2" 
              color="pink" 
              text 
              @click="msgSuccess = false"
            >Close</v-btn>
          </v-col>
        </v-row>        
      </v-card>
    </v-dialog>
    <DeleteGroup :open="openDeleteDialog" :group="group" @close="close($event)"/>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DeleteGroup from './DeleteGroup.vue'
export default {
  name: 'AddGroupDialog',
  props: {
    open: { type: Boolean, default: false },
    editMode: { type: Boolean, default: false },
  },
  components: { DeleteGroup },
  computed: {
    ...mapState({
      user: state => state.authentication.user,
      green_gateways: state => state.gateways.greenGateways,
      groups: state => state.nodes.groups
    })
  },
  data() {
    return {
      loading: false,
      msgSuccess: false,
      meterList: [],
      metersSelected: [],
      groupName: '',
      description: '',
      error: false,
      errMsg: '',
      groupID: '',
      group: {
        name: '',
        _id: '',
        meters: [],
        description: ''
      },
      openDeleteDialog: false,
    }
  },
  methods: {
    ...mapActions({ 
        addGroup: 'nodes/addGroup', 
        editGroup: 'nodes/editGroup',
        retrieve_data: 'nodes/retrieve_data',
      }),
    async saveGroup(name, metersSelected, description, id){
      try {
        let groups = [...this.groups]

        if (this.editMode) {
          groups = groups.filter(g => g._id != id)
        } 

        this.loading = true
        let meters = []
        metersSelected.forEach(meter => {
          let key = meter.name ?? meter
          let objMeter = this.meterList.find(m => m.name === key)
          meters.push(objMeter)
        })
        
        let checkedkName = groups.find(g => g.name.toLowerCase().replaceAll(/\s/g,'') === name.toLowerCase().replaceAll(/\s/g,''))
        
        if (checkedkName) throw new Error('Group already exists, type another name')

        let status = this.editMode 
                      ? await this.editGroup({ name: name.trim(), description, meters:meters.map(m => m.id), id }) 
                      : await this.addGroup({ name: name.trim(), description: description, meters: meters.map(m => m.id) })
        if (!status) throw new Error(`Group could not be ${this.editMode ? 'edited' : 'added'}, try again later`)
        await this.sleep(3000).then(() => {
          this.loading = false
          this.close(true); 
          this.msgSuccess = true 
        })
        await this.sleep(3000).then(() => {
          this.msgSuccess = false 
        })
      } catch (error) {
        this.errMsg = error
        this.loading = false
        this.error = true
        await this.sleep(3000).then(() => {
          this.error = false 
        })
      }
    },
    async refreshData(){
      await this.retrieve_data()
    },
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    close(status){
      this.openDeleteDialog = false
      if(!status) return
      this.groupName = ''
      this.metersSelected = []
      this.description = ''
      this.$emit('close')
    },
    remove(meter){
      let index = this.metersSelected.findIndex(m => m.name === meter.name)
      if (index >= 0) this.metersSelected.splice(index, 1)
    }
  },
  mounted() {
    this.green_gateways.forEach(greenGtw => {
      greenGtw.meters.forEach(meter => {
        let {id, name} = meter
        this.meterList.push({id, name})
      });
    });
  },
  watch: {
    'group'(){
        this.groupName = this.group.name ?? ''
        this.metersSelected = this.meterList.filter((m) =>
        this.group.meters.includes(m.id)) ?? []
        this.groupID = this.group._id ?? ''
        this.description = this.group.description ?? ''
    }
  }
};
</script>

<style scoped>
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  10%, 90% { transform: translate3d(-1px, 0, 0); }
  20%, 80% { transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
  40%, 60% { transform: translate3d(4px, 0, 0); }
}
</style>