<template>
    <div>
        <v-dialog v-model="openDialog" persistent width="500px">
            <v-card style="overflow: hidden;">
                <v-card-title>Change theme colors
                    <v-spacer></v-spacer>
                    <v-btn icon @click="$emit('close')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-row class="pa-5">
                    <v-col cols="12">
                        <v-text-field v-model="primaryColor" outlined dense label="Primary Color">
                            <template v-slot:append-outer>
                                <v-icon :color="computedPrimaryColor">mdi-square</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="secondaryColor" outlined dense label="Secondary Color">
                            <template v-slot:append-outer>
                                <v-icon :color="computedSecondaryColor">mdi-square</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-if="error" cols="12" class="my-n2 d-flex justify-end align-center">
                        <p class="red--text">Changes could not be saved, try again later</p>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-end align-center">
                        <v-btn color="primary" :loading="loading" class="my-n2" @click="saveColors(primaryColor, secondaryColor)">Save</v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-dialog v-model="success" width="500px">
            <v-card color="primary" class="text-center">
                <v-card-title class="white--text">Color configuration saved successfully</v-card-title>
            </v-card>
        </v-dialog>
    </div>
  </template>

<script>
import { mapState, mapActions } from 'vuex';
import { db } from '../../../plugins/firebase';

export default {
  name: 'ColorDialog',
  props: {
    openDialog: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      user: (state) => state.authentication.user,
    }),
    computedPrimaryColor() {
      return this.isValidHexColor(this.primaryColor) ? this.primaryColor : '#C5C1C3';
    },
    computedSecondaryColor() {
      return this.isValidHexColor(this.secondaryColor) ? this.secondaryColor : '#C5C1C3';
    },
  },
  data() {
    return {
      primaryColor: null,
      secondaryColor: null,
      loading: false,
      error: false,
      success: false,
    };
  },
  methods: {
    ...mapActions({ retrieve_data: 'nodes/retrieve_data' }),
    isValidHexColor(value) {
      const hexRegex = /^#([0-9A-Fa-f]{3}){1,2}$/i;
      return hexRegex.test(value);
    },
    async saveColors(first, second) {
      try {
        this.error = false;
        this.loading = true;

        await db.doc(`usersGreenweb/${this.user.uid}`).update({
          colors: {
            primary: first,
            secondary: second,
          },
        });

        this.loading = false;
        this.$vuetify.theme.themes.light.primary = first;
        this.$vuetify.theme.themes.light.secondary = second;
        this.$emit('close');
        this.success = true;

        await this.sleep().then(() => {
          this.success = false;
        });
      } catch (error) {
        this.error = true;
        this.loading = false;
        this.$vuetify.theme.themes.light.primary = this.colors.primary;
        this.$vuetify.theme.themes.light.secondary = this.colors.secondary;
        await this.sleep().then(() => {
          this.error = false;
        });
      }
    },
    async refresh() {
      await this.retrieve_data();
    },
    sleep() {
      const WAIT_UNTIL_CHANGES_APPLY = 3000;
      return new Promise((resolve) => setTimeout(resolve, WAIT_UNTIL_CHANGES_APPLY));
    },
  },
  created() {
    if (this.user) {
      db.collection('usersGreenweb').onSnapshot((res) => {
        const changes = res.docChanges();
        if (changes.length > 0) {
          localStorage.removeItem('colors');
          this.refresh();
        }
      });
    }
  },
  mounted() {
    this.primaryColor = this.$vuetify.theme.themes.light.primary;
    this.secondaryColor = this.$vuetify.theme.themes.light.secondary;
  },
};
</script>

  <style scoped></style>
