<template>
  <v-app style="font-family: 'Montserrat' !important; background-color: #F0F2F4">
    <v-expand-x-transition v-if="is_app_ready">
      <div >
        <Toolbar v-if="$route.name != 'Login' && user"/>
        <Drawer v-if="get_drawer_state && user"/>
        <ContentView/>
      </div>
    </v-expand-x-transition>
    <transition v-else>
      <div class="load-screen d-flex">
        <div class="ma-auto">
          <img src="@/assets/logo.png" alt="logo" class="mb-3" height="100px" />
          <v-progress-linear color="primary" indeterminate rounded />
        </div>
      </div>
    </transition>
    <NewVersion 
      :updateExists="updateExists" 
      @updateNow="updateApp" 
      @updateLater="updateExists = false" 
    />
    <v-snackbar v-model="newVersion" :timeout="4000">
      The app has been updated.
      <template v-slot:action="{ attrs }">
        <v-btn 
          color="primary" 
          text 
          v-bind="attrs" 
          @click="newVersion = false"
        >Close</v-btn>
      </template>
    </v-snackbar>
 </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Drawer from './components/core/Drawer';
import Toolbar from './components/core/Toolbar';
import ContentView from './components/core/ContentView';
import NewVersion from './components/NewVersionNotification';

export default {
  name: 'App',
  components: {
    Toolbar,
    Drawer,
    ContentView,
    NewVersion,
  },
  data() {
    return {
      ready: false,
      show: false,
      refreshing: false,
      registration: null,
      updateExists: false,
      newVersion: false,
    };
  },
  created() {
    const zid = localStorage.getItem('zone_idx');
    if (zid != null) { this.show = true; }
  },
  mounted() {
    document.addEventListener('swUpdated', this.showNotification);
      if (navigator.serviceWorker) {  
        navigator.serviceWorker.addEventListener(
          'controllerchange', () => {
            if (this.refreshing) return;
            this.refreshing = true;
            window.location.reload();
          }
        );
      }
    const newVersion = localStorage.getItem('newVersion');
    if (newVersion) {
      setTimeout(() => {this.newVersion = true;}, 500);
      setTimeout(() => {localStorage.removeItem('newVersion')}, 1000);
    }
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.set_page_online_status(window.navigator.onLine);
    setTimeout(() => {
      this.ready = true;
    }, 1000);
    const version = '2.0.1';
    console.log(`GREENWEB ${version}`);
    localStorage.removeItem('colors');
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
    document.removeEventListener('swUpdated', this.showNotification);
  },
  methods: {
    updateOnlineStatus(event) {
      const { type } = event;
      this.set_page_online_status(type === 'online');
    },
    updateApp () {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) { return; }
      this.registration.waiting.postMessage('skipWaiting');
    },
    showNotification(event) {
      this.updateExists = true;
      this.registration = event.detail;
      localStorage.setItem('newVersion', true);
    },
    ...mapActions({
      open_socket_connection: 'socket/open_connection',
      set_token: 'authentication/set_token',
      set_page_online_status: 'app/set_page_online_status',
    }),
  },
  computed: {
    ...mapState({
      is_logged_user: (state) => state.authentication.status.loggedIn,
      user: (state) => state.authentication.user,
    }),
    ...mapGetters({
      get_drawer_state: 'app/get_drawer_state',
      is_app_ready: 'app/get_ready_state',
    }),
  },
  watch: {
    $route(){
      const LOGIN = '/login'
      const route = this.$route.path === LOGIN ? '/' : this.$route.path
      localStorage.setItem('currentRoute', route)
    },
  }
};
</script>

<style>
.application {
  font-family: 'Montserrat', sans-serif, !important;
}
.fontMontserrat {
  font-family: 'Montserrat', sans-serif, !important;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{
  background-color: #f2f2f2;
  min-height: 100vh;
}
.load-screen {
  min-height: 100%;
  min-width: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
.full-container{
  min-height: 100vh;
  min-width: 100vw;
}
.content-center{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
