<template>
  <div>
    <v-dialog v-model="open" persistent width="650px">
      <v-card style="overflow: hidden;">
        <v-card-title>{{ (edit_mode) ? 'Edit' : 'Add'}} {{ product }}
          <v-spacer></v-spacer>
          <v-btn v-if="valid" icon @click="refreshData(valData.id, valData.key)"><v-icon>mdi-refresh</v-icon></v-btn>
          <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-alert dismissible v-if="(get_alert_message != null)" :type="get_alert_type">{{ get_alert_message }}</v-alert>
        <v-form class="mt-n3 pa-6" ref="form" lazy-validation v-if="mode === 'gateway'">
            <v-card flat outlined>
              <v-toolbar flat>
                  <v-row class="mr-n9">
                    <v-col cols="5" class="mt-6"><v-text-field v-model="valData.id" :disabled="valid" label="ID"></v-text-field></v-col>
                    <v-col cols="5" class="mt-6"><v-text-field v-model="valData.key" :disabled="valid" label="Key" flat hide-details></v-text-field></v-col>
                    <v-col cols="2" class="mt-6"><v-btn color="primary" :disabled="valid || valData.id === '' || valData.key === ''" small :loading="loadingGatewayData" @click="validate(valData.id, valData.key, version)"><v-icon>mdi-check</v-icon></v-btn></v-col>
                  </v-row>
              </v-toolbar>
            </v-card>
            <transition name="shake" v-if="err1"><p class="red--text text-center shake mt-4 mb-n2">Wrong data, check and type again</p></transition>
            <v-card v-if="valid" flat class="mt-5 px-6">
                <div align="center" v-if="!loadingItems">
                  <v-row class="my-n5">
                    <v-col cols="2"></v-col>
                    <v-col cols="3"><v-card-text>Name</v-card-text></v-col>
                    <v-col cols="3"><v-card-text>Fare</v-card-text></v-col>
                    <v-col cols="3"><v-card-text>Capacity</v-card-text></v-col>
                    <v-col cols="1"></v-col>
                  </v-row>
                  <v-row v-for="(meter, index) in meters" :key="index" justify="center">
                    <v-col cols="2" class="mt-2 font-weight-bold">{{meter.name}}</v-col>
                    <v-col cols="3"><v-text-field v-model="meters[index].alias" :disabled="!meters[index].edit" label="Name" outlined flat dense solo hide-details></v-text-field></v-col>
                    <v-col cols="3"><v-text-field v-model="meters[index].fare" :disabled="!meters[index].edit" label="Fare" type="number" outlined flat dense solo hide-details></v-text-field></v-col>
                    <v-col cols="3"><v-text-field v-model="meters[index].capacity" :disabled="!meters[index].edit" label="Watts" type="number" outlined flat dense solo hide-details></v-text-field></v-col>
                    <v-col cols="1"><v-btn v-model="meters[index].edit" fab icon x-small color="primary" @click="test(index, meters[index].edit)"><v-icon>{{ meters[index].edit ? 'mdi-content-save' : 'mdi-pencil' }}</v-icon></v-btn></v-col>
                  </v-row>
                </div>
                <div class="text-center align-center mt-5" v-else>
                  <v-row>
                    <v-col>
                        <v-progress-circular :indeterminate="true" width="5" size="50" color="primary"></v-progress-circular>
                        <br>
                        <p class="mt-4 grey--text">Loading meters...</p>
                    </v-col>
                  </v-row>
                </div>
            </v-card>
            <transition name="shake" v-if="err2"><p class="red--text text-center shake">{{errorMsgs[errIdx]}}{{ repeat ? `: ${nameRepeat}` : '' }}</p></transition>
            <v-btn color="primary" class="mt-4 edit_inactive" :loading="loadingSaveMeters" v-if="valid" @click="save()">Save</v-btn>
        </v-form>
        <v-form v-else class="mt-n3 pa-6" ref="form" lazy-validation>
          <v-row v-for="(gm, index) in green_mts" :key="index" justify="center" class="mt-n2">
            <v-col cols="6"><v-text-field v-model="gm.alias" @click="completeData()" @input="completeData()" label="Display Name" outlined flat dense hide-details :append-icon="index != 0 ? 'mdi-minus-circle' : ''" @click:append="()=>{ green_mts.splice(index,1); completeData() }"></v-text-field></v-col>
            <v-col cols="6"><v-text-field v-model="gm.id" @click="completeData()" @input="completeData()" label="Meter ID" outlined flat dense hide-details></v-text-field></v-col>
            <v-col cols="6"><v-text-field v-model="gm.fare" @click="completeData()" @input="completeData()" label="Fare" type="number" outlined flat dense hide-details></v-text-field></v-col>
            <v-col cols="6"><v-text-field v-model="gm.capacity" @click="completeData()" @input="completeData()" label="Capacity (Watts)" type="number" outlined flat dense hide-details></v-text-field></v-col>
            <v-col><v-divider></v-divider></v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end align-end my-n3">
              <transition v-if="err1" name="shake"><p class="red--text text-center shake">{{errorMsgs[errIdx]}}</p></transition>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end align-end">
              <v-btn color="secondary" class="mr-2" elevation="0" :disabled="green_mts[0].alias === '' || green_mts[0].id === ''" @click="()=>{ green_mts.push({ alias: '', id: '', fare: 0, capacity: 0 }); completeData() }">Add meter</v-btn>
              <v-btn color="primary" elevation="0" :disabled="!complete" @click="saveGreenMeters()" :loading="loadingGatewayData">Save</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
      <v-dialog elevation="0" v-model="msgSuccess" max-width="350px" style="overflow: hidden" >
        <v-card elevation="0" color="#323232" flat class="pl-4 pr-n1" height="55px" width="350px" style="overflow: hidden; position: absolute; bottom: 0;">
          <v-row>
            <v-col cols="8" class="mt-1"><p class="white--text" style="font-size: 14px">{{ version === '1.0' ? 'Meter' : 'Gateway' }} added successfully</p></v-col>
            <v-col cols="4" class="mt-n1"><v-btn class="ml-2" color="pink" text @click="msgSuccess = false">Close</v-btn></v-col>
          </v-row>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import DeleteItem from './DeleteItem.vue';
import GatewayMeter from '../api/gatewayMeter.api';

export default {
  name: 'CreateSchedule',
  components: { DeleteItem },
  props: {
    open: { type: Boolean, default: false },
    index: { type: Number, default: -1 },
    edit_mode: { type: Boolean, default: false },
    version: { type: String, default: '1.0' },
    mode: { type: String, default: 'gateway' },
    gtw: { type: String, default: '' },
    product: { type: String, default: 'meter' },
  },
  data() {
    return {
      complete: false,
      valid: false,
      loadingGatewayData: false,
      loadingSaveMeters: false,
      err1: false,
      err2: false,
      errIdx: 0,
      errorMsgs: ['Gateway not found', 'Gateway already registered', 'Invalid key', 'Name/ID already taken by other gateway', 'Data incomplete', 'Name/ID repeated within current gateway', 'Data could not be added, try again'],
      msgSuccess: false,
      openDelete: false,
      typeItem: 'Meter',
      valData: { id: '', key: '' },
      meters: [],
      green_mts: [{
        alias: '', id: '', fare: 0, capacity: 0,
      }],
      editItem: false,
      loadingItems: false,
      meterIdx: -1,
      repeat: false,
      nameRepeat: '',
    };
  },
  methods: {
    ...mapActions({ addMeter: 'nodes/addMeter', addGreenGateway: 'gateways/addGreenGateway', addGreenMeters: 'gateways/addGreenMeters' }),
    async validate(id, key, vr) {
      this.loadingGatewayData = true;
      this.resetMeters();
      const gateway = { id, key };
      if (vr === '1.0') {
        const g = this.gateway_meters.find((g) => g.gateway.id === id);
        if (g) { this.err1 = true; this.loadingGatewayData = false; this.errIdx = 1; return await this.sleep(3000).then(() => { this.err1 = false; }); }
        try {
          const meters = await GatewayMeter.get(gateway);
          console.log(meters);
          const metersAPI = meters.data;
          metersAPI.forEach((element) => {
            const m = {
              name: element.name, edit: false, alias: '', fare: 0, capacity: 0,
            }; this.meters.push(m);
          });
          await this.sleep(2000).then(() => { this.loadingGatewayData = false; });
          this.valid = true;
        } catch (error) {
          this.err1 = true;
          this.loadingGatewayData = false;
          if (error.response.status === 400) this.errIdx = 2;
          else if (error.response.status === 404) this.errIdx = 0;
          await this.sleep(3000).then(() => { this.err1 = false; });
        }
      } else {
        try {
          const gtw = { key, name_eui: id, name: id };
          await this.addGreenGateway(gtw);
          this.loadingGatewayData = false;
          await this.close();
          this.msgSuccess = true;
          await this.sleep(3000).then(() => { this.msgSuccess = false; });
        } catch (error) {
          this.err1 = true;
          this.loadingGatewayData = false;
          if (error.response.status === 400) this.errIdx = 2;
          else if (error.response.status === 404) this.errIdx = 0;
          await this.sleep(3000).then(() => { this.err1 = false; });
        }
      }
    },
    async save() {
      this.meters.forEach((meter) => { meter.edit = false; });
      this.loadingSaveMeters = true;
      const len = this.meters.length;
      let sum = 0;
      let r = 0;
      this.meters.forEach((element) => { if (element.fare > 0 && element.alias != '' && element.capacity) sum += 1; });
      if (len === sum) {
        this.gateway_meters.forEach((element) => {
          element.meters.forEach((meter) => {
            const g = this.meters.find((m) => m.alias === meter.name);
            if (g) { return this.loadingSaveMeters = false, this.err2 = true, this.repeat = true, this.nameRepeat = g.alias, this.errIdx = 3, r += 1, this.sleep(3000).then(() => { this.err2 = false, this.repeat = false, this.nameRepeat = ''; }); }
          });
        });

        localStorage.setItem('met2breg', JSON.stringify(this.meters));
        const c = 0;
        for (let index = 0; index < this.meters.length; index++) {
          const met2breg = JSON.parse(localStorage.getItem('met2breg'));
          const elem = met2breg[index];
          met2breg.splice(index, 1);
          met2breg.forEach((meter) => {
            if (meter.alias === elem.alias) {
              return this.loadingSaveMeters = false, this.err2 = true, this.repeat = true, this.nameRepeat = meter.alias, this.errIdx = 5, r += 1, this.sleep(3000).then(() => { this.err2 = false, this.repeat = false, this.nameRepeat = ''; });
            }
          });
        }

        this.sleep(3000).then(() => { this.loadingSaveMeters = false; });

        if (r > 0) return;
        if (c > 0) return;
        const mts_gtw = [];
        this.meters.forEach((element) => {
          const obj = {
            name: element.alias, fare: element.fare, id: element.name, capacity: element.capacity,
          };
          mts_gtw.push(obj);
        });
        const gtw_info = {
          gateway: { id: this.valData.id, key: this.valData.key },
          meters: mts_gtw,
          version: '1.0',
        };
        await this.addMeter(gtw_info);
        await this.sleep(2000).then(() => { this.loadingSaveMeters = false; });
        await this.close();
        this.msgSuccess = true;
        await this.sleep(3000).then(() => { this.msgSuccess = false; });
        localStorage.removeItem('met2breg');
      } else {
        this.loadingSaveMeters = false;
        this.err2 = true;
        this.errIdx = 4;
        return await this.sleep(3000).then(() => { this.err2 = false; });
      }
    },
    async saveGreenMeters() {
      try {
        let r = 0;
        for (let index = 0; index < this.green_gateways.length; index++) {
          const elem = this.green_gateways[index];
          elem.meters.forEach((m) => {
            const id = this.green_mts.find((n) => n.id === m.id);
            const alias = this.green_mts.find((n) => n.alias === m.name);
            if (id || alias) r += 1;
          });
        }
        if (r > 0) { return this.err1 = true, this.errIdx = 3, this.sleep(3000).then(() => { this.err1 = false; }); }

        localStorage.setItem('met2breg', JSON.stringify(this.green_mts));
        let c = 0;
        for (let index = 0; index < this.green_mts.length; index++) {
          const met2breg = JSON.parse(localStorage.getItem('met2breg'));
          const elem = met2breg[index];
          met2breg.splice(index, 1);
          met2breg.forEach((meter) => {
            if (meter.alias === elem.alias || meter.id === elem.id || meter.alias === elem.id || meter.id === elem.alias) { c += 1; }
          });
        }
        if (c > 0) { return this.err1 = true, this.errIdx = 5, this.sleep(3000).then(() => { this.err1 = false; }); }

        this.loadingGatewayData = true;
        const g = JSON.parse(localStorage.getItem('gsel'));
        const data = { gtw: g, mts: this.green_mts };
        await this.addGreenMeters(data);
        await this.sleep(2000).then(() => { this.loadingGatewayData = false; });
        await this.close();
        this.msgSuccess = true;
        await this.sleep(3000).then(() => { this.msgSuccess = false; });
      } catch (error) {
        this.loadingGatewayData = false;
        this.err1 = true;
        this.errIdx = 6;
        await this.sleep(3000).then(() => { this.err1 = false; });
      }
    },
    test(idx, mode) {
      this.meterIdx = idx;
      this.meters[idx].edit = !mode;
      if (this.meters[idx].edit) {
        for (let index = 0; index < this.meters.length; index++) {
          if (index != idx) this.meters[index].edit = false;
        }
      }
    },
    completeData() {
      this.complete = true;
      this.green_mts.forEach((gm) => {
        Object.keys(gm).forEach((attr) => {
          if (attr === 'id' || attr === 'alias') {
            if (gm[attr] === '') return this.complete = false;
          }
        });
      });
    },
    async refreshData(id, key) {
      this.resetMeters();
      this.loadingItems = true;
      const gateway = { id, key };
      const meters = await GatewayMeter.get(gateway);
      const metersAPI = meters.data;
      metersAPI.forEach((element) => {
        const m = {
          name: element.name, edit: false, alias: '', fare: 0, capacity: 0,
        }; this.meters.push(m);
      });
      await this.sleep(3000).then(() => { this.loadingItems = false; });
    },
    resetMeters() {
      this.meters = [];
    },
    closeDelete() {
      this.openDelete = false;
      this.$options.parent.i = -1;
      this.$emit('close');
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    close() {
      this.$emit('close');
      this.valid = false;
      this.valData = { id: '', key: '' };
      this.green_mts = [{
        alias: '', id: '', fare: 0, capacity: 0,
      }];
      this.complete = false;
      localStorage.removeItem('gsel');
      // console.log(this.$route.params);
    },
  },
  computed: {
    setColor() {
      const colors = ['grey darken-1', 'green lighten-1', 'green darken-1', 'green darken-3'];
    },
    ...mapGetters({
      get_alert_type: 'alert/get_type',
      get_alert_message: 'alert/get_message',
    }),
    ...mapState({
      gateway_meters: (state) => state.nodes.meters,
      green_gateways: (state) => state.gateways.greenGateways,
    }),
  },
};
</script>

<style scoped>
.my-input input{
  text-transform: uppercase
}
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  10%, 90% { transform: translate3d(-1px, 0, 0); }
  20%, 80% { transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
  40%, 60% { transform: translate3d(4px, 0, 0); }
}
.edit_active{
  position: relative;
  left: 575px;
}
.edit_inactive{
  position: relative;
  left: 530px;
}
.v-snackbar{
  position: relative;
  left: 50px
}
.custom-dialog {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
