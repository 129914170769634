const id_limit_count = 1000;

class ApiMessage {
  constructor() {
    this.PARAMS = {
      LV: null, // level or default value
      LS: null, // light sensor(0/1)
      TH: null, // threshold for light sensor(int)
      ID: null, // array of node identifier(s) as strings
      Z: null, // zone(string)
      K: null, // acknowledge
      D: null, // Distance of movement sensor(0-3)
      T: null, // Time of movement sensor(0-3)
      LH: null, // level high(0-3)
      LL: null, // level low(0-3)
      A: null, // automatic mode
      DM: null, // dimming mode(0/1)
      RP: null, // repeater mode(1/0)
      NET: null, // network ID (string)
      PASS: null, // network pass(string)
      RST: null, // reset neurolink (1)
    };

    this.message = {};

    // default message data
    this.message.meta = { client: 'web', Qos: 1 };
    this.message.data = {};

    this.operation = null;
  }

  /**
   *
   * @param key: String - Must be a valid registered param.
   * @param value: String
   */
  set_param(key, value) {
    if (!this.PARAMS.hasOwnProperty(key)) { throw Error('Key doesn\'t exists.'); }

    if (typeof value === 'boolean') { value = value ? 1 : 0; }

    // if everything is valid set the param
    this.PARAMS[key] = value;
  }

  /**
   *
   * @param ids - array of strings
   */
  set_node_identifiers(ids) {
    this.PARAMS.ID = ids;
  }

  /**
   *
   * @param key
   * @returns returns the value of the given param key
   */
  get_param(key) {
    if (!this.PARAMS.hasOwnProperty(key)) { throw Error('Key doesn\'t exists.'); }

    return this.PARAMS[key];
  }

  get_params() {
    return this.PARAMS;
  }

  get_not_null_params() {
    const not_null_params = {};

    for (const [key, value] of Object.entries(this.PARAMS)) {
      if (value !== null) { not_null_params[key] = value; }
    }

    return not_null_params;
  }

  set_meta(meta_object) {
    if (meta_object != null) { this.message.meta = meta_object; }
  }

  set_data(data_object) {
    if (data_object != null) { this.message.data = data_object; }
  }

  set_client(client) {
    this.get_meta().client = client;
  }

  /**
   * Builds up the message
   * @returns {{}|*}
   */
  get_message() {
    // add the params object if we have to
    const _params = {};

    for (const [key, value] of Object.entries(this.PARAMS)) {
      if (value !== null && key !== 'ID') { _params[key] = value; }
    }

    if (Object.keys(_params).length) { this.set_command({ params: _params }); }

    if (this.PARAMS.ID) { this.get_command().ni = this.PARAMS.ID; } // di means 'node identifier(s)'

    // if we have something in command property add the next things
    if (!this.get_command().type) { this.get_command().type = 'node'; } // default value for type in command (it can be something like 'measure' or whatever)

    if (this.operation) { this.get_command().operation = this.operation; }

    return this.message;
  }

  set_command(command_object) {
    this.message.data = command_object;
  }

  set_message_operation(operation) {
    this.operation = operation;
  }

  get_command() {
    return this.message.data;
  }

  get_meta() {
    return this.message.meta;
  }

  get_message_id() {
    return this.message.meta.timestamp;
  }

  get_data() {
    return this.message.data;
  }

  set_message_id(timestamp_id) {
    this.message.meta.timestamp = timestamp_id;
  }
}

export const PARAM = {
  LV: 'LV', // level or default value
  LS: 'LS', // light sensor(0/1)
  TH: 'TH', // threshold for light sensor(int)
  ID: 'ID', // array of node identifier(s) as strings
  Z: 'Z', // zone(string)
  K: 'K', // acknowledge
  D: 'D', // Distance of movement sensor(0-3)
  T: 'T', // Time of movement sensor(0-3)
  LH: 'LH', // level high(0-3)
  LL: 'LL', // level low(0-3)
  A: 'A', // automatic mode
  DM: 'DM', // dimming mode(0/1)
  RP: 'RP', // repeater mode(1/0)
  NET: 'NET', // network ID (string)
  PASS: 'PASS', // network pass(string)
  RST: 'RST', // reset neurolink (1)
};

export const ParamInfo = {
  LV: {
    name: 'Power-on level',
    description: 'Level of ignition',
  }, // level or default value
  LS: {
    name: 'Light sensor mode',
    description: '',
  }, // light sensor(0/1)
  TH: {
    name: 'Light sensor threshold',
    description: '',
  }, // threshold for light sensor(int)
  ID: {
    name: 'Nodes identifiers',
    description: 'Devices to receive the instruction',
  }, // array of node identifier(s) as strings
  Z: {
    name: 'Zone(s)',
    description: 'Zone(s) to receive the instruction',
  }, // zone(string)
  K: {
    name: 'Acknowledge',
    description: '',
  }, // acknowledge
  D: {
    name: 'Movement sensor distance',
    description: '',
  }, // Distance of movement sensor(0-3)
  T: {
    name: 'Movement sensor time',
    description: '',
  }, // Time of movement sensor(0-3)
  LH: {
    name: 'Level high',
    description: '',
  }, // level high(0-3)
  LL: {
    name: 'Level low',
    description: '',
  }, // level low(0-3)
  A: {
    name: 'Automatic mode',
    description: '',
  }, // automatic mode
  DM: {
    name: 'Dimming mode',
    description: '',
  }, // dimming mode(0/1)
  RP: {
    name: 'Repeater mode',
    description: '',
  }, // repeater mode(1/0)
  NET: {
    name: 'Network ID',
    description: '',
  }, // network ID (string)
  PASS: {
    name: 'Network Pass',
    description: '',
  }, // network pass(string)
  RST: {
    name: 'Reset Neurolink',
    description: '',
  }, // reset neurolink (1)
};

export default ApiMessage;
