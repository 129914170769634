import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// import { nodes } from '../store/modules/nodes.js';

Vue.use(Vuetify);

// console.log(nodes);
const colors = JSON.parse(localStorage.getItem('colors'));

export default new Vuetify(
  {
    customVariables: ['~/assets/variables.scss'],
    theme: {
      themes: {
        light: {
          primary: colors ? colors.primary : '#06D676',
          secondary: colors ? colors.secondary : '#023243',
          accent: '#023243',
          background: '#F0F2F4',
          danger: '#FC5919',
        },
        dark: {
          primary: '#2ca23a',
          secondary: '#0284a3',
          accent: '#02abe8',
          background: '#F0F2F4',
        },
      },
      options: {
        customProperties: true,
      },
      light: process.env.VUE_APP_THEME_COLOR === 'light',
    },
  },
);
