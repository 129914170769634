import axios from 'axios';

const apiMeterClient2 = axios.create({
  baseURL: process.env.VUE_APP_METER_API,
  headers: {
    'Content-Type': 'application/json',
    'auth-api': process.env.VUE_APP_METER_API_TOKEN,
  },
});

const responseInterceptor = (response) => {
  const OK_CODE = 200;
  if (response.status == OK_CODE) return response.data;
  return Promise.reject(response);
};

apiMeterClient2.interceptors.response.use(responseInterceptor);

export default apiMeterClient2;
