/*
This module handles the app ui state
 */

export const app = {
  namespaced: true,
  state: {
    online: false,
    drawer: {
      visible: false, // false
      expandOnHover: true,
      miniVariant: true,
      image: null,
    },
    connected_counter: 0,
    bottomSheet: false,
    ready: false,
  },
  actions: {
    set_drawer_visible({ commit }, payload) {
      commit('SET_DRAWER_VISIBLE', payload);
    },
    set_bottom_sheet_visible({ commit }, payload) {
      commit('SET_BOTTOM_SHEET_VISIBLE', payload);
    },
    set_page_online_status({
      commit, getters, rootGetters, dispatch,
    }, payload) {
      commit('SET_PAGE_ONLINE_STATUS', payload);
    },
    set_app_ready_status({ commit }, payload) {
      commit('SET_APP_READY_STATUS', payload);
    },
  },
  mutations: {
    SET_IMAGE(state, payload) {
      state.drawer.image = payload;
    },
    SET_DRAWER_VISIBLE(state, payload) {
      state.drawer.visible = payload;
    },
    SET_BOTTOM_SHEET_VISIBLE(state, payload) {
      state.bottomSheet = payload;
    },
    SET_MINI_VARIANT(state, payload) {
      state.drawer.miniVariant = payload;
    },
    SET_EXPAND_ON_HOVER(state, payload) {
      state.drawer.expandOnHover = payload;
    },
    SET_PAGE_ONLINE_STATUS(state, payload) {
      state.online = payload;
      ++state.connected_counter;
    },
    SET_APP_READY_STATUS(state, payload) {
      state.ready = payload;
    },
  },
  getters: {
    get_drawer_state(state) {
      return state.drawer.visible;
    },
    get_bottom_sheet(state) {
      return state.bottomSheet;
    },
    get_page_online_status(state) {
      return state.online;
    },
    get_connected_counter(state) {
      return state.connected_counter;
    },
    get_ready_state(state) {
      return state.ready;
    },
  },

};
