<template>
  <div>
    <v-dialog v-model="dialog" persistent width="400px">
      <v-card>
        <v-card-title>
          <v-row justify="space-between" no-gutters>
            <v-col> Assign main gateway </v-col>
            <v-col cols="auto">
              <v-btn icon @click="closeDialog()" :disabled="loadingSubmit">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" class="text-center">
              Do you want to assign <strong>{{ gateway.name }}</strong> as the main
              gateway?
            </v-col>
            <v-col cols="12" v-if="errorMessage !== ''">
              <p
                class="red--text text-center mb-0 pt-1 shake font-weight-medium"
              >
                {{ errorMessage }}
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters justify="end" class="pt-5">
            <v-col cols="auto">
              <v-btn
                color="primary"
                :loading="loadingSubmit"
                @click="assignAsMain()"
              >
                Assign
              </v-btn>
              <v-btn
                class="ml-2"
                color="blue-grey"
                @click="closeDialog()"
                :disabled="loadingSubmit"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showSnackbar" :timeout="3000" class="pr-0">
      Gateway assigned successfully
      <v-btn color="pink" text @click="showSnackbar = false"> Close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AssignGateway",
  props: {
    dialog: { type: Boolean, default: false },
    gateway: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      loadingSubmit: false,
      errorMessage: "",
      showSnackbar: false,
    };
  },
  methods: {
    ...mapActions({ assignGateway: "gateways/assignGateway" }),
    async assignAsMain() {
      this.errorMessage = "";
      this.loadingSubmit = true;
      try {
        await this.assignGateway(this.gateway.id);
        this.showSnackbar = true;
        this.$emit("close");
      } catch (error) {
        this.errorMessage =
          error.response.data.data ||
          "Gateway could not be assigned as main, please try again later";
      } finally {
        this.loadingSubmit = false;
      }
    },
    closeDialog() {
      this.errorMessage = "";
      this.$emit("close");
    },
  },
};
</script>
