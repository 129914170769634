<template>
  <div>
    <v-dialog v-model="dialog" persistent width="400px">
      <v-card>
        <v-card-title>
          <v-row justify="space-between" no-gutters>
            <v-col> Delete gateway </v-col>
            <v-col cols="auto">
              <v-btn icon @click="closeDialog()" :disabled="loadingDelete">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" class="text-center">
              Are you sure you want to delete
              <strong> {{ gateway.name }}</strong
              >?
            </v-col>
            <v-col cols="12" v-if="errorMessage !== ''">
              <p
                class="red--text text-center mb-0 pt-1 shake font-weight-medium"
              >
                {{ errorMessage }}
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters justify="end" class="pt-5">
            <v-col cols="auto">
              <v-btn
                color="red"
                :loading="loadingDelete"
                @click="confirmDeletion()"
              >
                Delete
              </v-btn>
              <v-btn
                class="ml-2"
                color="blue-grey"
                @click="closeDialog()"
                :disabled="loadingDelete"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showSnackbar" :timeout="3000" class="pr-0">
      Gateway deleted successfully
      <v-btn color="pink" text @click="showSnackbar = false"> Close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "DeleteGateway",
  props: {
    dialog: { type: Boolean, default: false },
    gateway: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapState({
      greenGateways: (state) => state.gateways.greenGateways,
    }),
  },
  data() {
    return {
      loadingDelete: false,
      showSnackbar: false,
      errorMessage: "",
    };
  },
  methods: {
    ...mapActions({
      deleteGateway: "gateways/deleteGateway",
      deleteGreenGateway: "gateways/deleteGreenGateway",
    }),
    async confirmDeletion() {
      this.errorMessage = "";
      this.loadingDelete = true;
      try {
        await this.deleteGateway(this.gateway.id);
        if (this.gateway.id === this.greenGateways[0]?.gateway?.id) {
          await this.deleteGreenGateway(this.gateway.id);
        }
        this.showSnackbar = true;
        this.closeDialog();
      } catch (error) {
        this.errorMessage =
          error.response.data.error ||
          "Gateway could not be deleter, please try again later.";
      } finally {
        this.loadingDelete = false;
      }
    },
    closeDialog() {
      this.errorMessage = "";
      this.$emit("close");
    },
  },
};
</script>
