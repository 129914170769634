import { db } from "../../plugins/firebase";
import { GatewayGreen, MeterGreen, Gateway } from "../../api";

export const gateways = {
  namespaced: true,
  state: {
    greenGateways: [],
    gateways: [],
    mainGateway: null
  },
  getters: {
    getAllMeters(state) {
      return state.greenGateways
        .flatMap((gateway) => {
          return gateway.meters;
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    },
  },
  actions: {
    async fetchGreenGateways({ dispatch, rootState, commit }) {
      const collection = `${rootState.nodes.collection}/greenGateway`;
      try {
        const greenGateways = JSON.parse(localStorage.getItem("greenGateway"));
        if (!greenGateways) {
          const fbGateways = await db.collection(collection).get();
          const gatewaysData = fbGateways.docs.map((gateway) => gateway.data());
          const formattedGateways = gatewaysData.map((data) => ({
            gateway: { id: data.name_eui, key: data.key },
            meters: [],
          }));
          for (const gateway of formattedGateways) {
            const gatewayId = gateway.gateway.id;
            const meters = await dispatch("fetchGreenMeters", gatewayId);
            gateway.meters = meters.map((meter) => ({ ...meter, gatewayId }));
          }
          commit("SET_GREEN_GATEWAYS", formattedGateways);
          return;
        }
        commit("SET_GREEN_GATEWAYS", greenGateways);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchGreenMeters({ rootState }, gatewayId) {
      const collection = rootState.nodes.collection;
      const meters = await db
        .collection(`${collection}/greenGateway/${gatewayId}/meters`)
        .get();
      return meters.docs.map((meter) => meter.data());
    },
    async addGreenGateway({ rootState, commit }, data) {
      try {
        const { key, name_eui, name } = data;
        const response = await GatewayGreen.register(key, name_eui, name);
        if (!response.data.message.includes("succesfully")) return false;
        const collection = `${rootState.nodes.collection}/greenGateway`;
        await db.runTransaction(async () => {
          await db.doc(`${collection}/${name_eui}`).set(data);
        });
        const formattedGateway = [
          {
            gateway: { id: name_eui, key },
            meters: [],
          },
        ];
        commit("SET_GREEN_GATEWAYS", formattedGateway);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async deleteGreenGateway({ rootState, commit }, id) {
      try {
        const collection = `${rootState.nodes.collection}/greenGateway`;
        await db.runTransaction(async () => {
          await db.doc(`${collection}/${id}`).delete();
        });
        commit("DELETE_GREEN_GATEWAY", id);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async addGreenMeters({ rootState, commit }, data) {
      try {
        const { gtw: gateway, mts: meters } = data;
        const newMeters = [];
        for (let index = 0; index < meters.length; index++) {
          const { capacity, fare, id, name } = meters[index];
          const collection = `${rootState.nodes.collection}/greenGateway/${gateway}/meters`;
          const response = await MeterGreen.addDevice(id, name);
          if (response.data.status !== 200) return false;
          await db.runTransaction(async () => {
            await db
              .doc(`${collection}/${id}`)
              .set({ name, id, capacity, fare });
          });
          newMeters.push({ name, id, fare, capacity });
        }
        commit("ADD_METERS", newMeters);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async editGreenMeters({ rootState, commit }, { gateway, meter }) {
      try {
        const { id, name, fare, capacity } = meter;
        const response = await MeterGreen.updateDisplayName(id, name);
        const collection = rootState.nodes.collection;
        if (response.data.status !== 200) return false;
        await db.runTransaction(async (transaction) => {
          const meterRef = db.doc(
            `${collection}/greenGateway/${gateway}/meters/${id}`
          );
          const currentSnapshot = await transaction.get(meterRef);
          if (!currentSnapshot.exists) throw new Error("Meter does not exist");
          transaction.update(meterRef, { name, fare, capacity });
        });
        commit("EDIT_METER", meter);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async deleteGreenMeters({ state, rootState, commit }, { gateway, meter }) {
      try {
        const collection = rootState.nodes.collection;
        const response = await MeterGreen.deletion(meter);
        if (!response.data) return false;
        await db.runTransaction(async (transaction) => {
          const meterRef = db.doc(
            `${collection}/greenGateway/${gateway}/meters/${meter}`
          );
          const currentSnapshot = await transaction.get(meterRef);
          if (!currentSnapshot.exists) throw new Error("Meter does not exist");
          transaction.delete(meterRef);
        });
        commit("DELETE_METER", meter);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async fetchGateways({ commit }) {
      try {
        const gateways = JSON.parse(localStorage.getItem("gateways"));
        if (!gateways) {
          const response = await Gateway.get();
          const formattedGateways = response.data.map((gateway) => {
            const { name_eui: id, name, main, key } = gateway;
            return { name, main, key, id };
          });
          commit("SET_GATEWAYS", formattedGateways);
          return;
        }
        commit("SET_GATEWAYS", gateways);
      } catch (error) {
        console.log(error);
      }
    },
    async addGateway({ commit }, data) {
      try {
        const { key, id, name } = data;
        await Gateway.post(key, id, name);
        commit("ADD_GATEWAY", data);
      } catch (error) {
        console.error(error.response.data.data);
        throw error;
      }
    },
    async assignGateway({ commit, state }, id) {
      try {
        const mainAssigned = state.gateways.some((gateway) => gateway.main);
        mainAssigned && (await Gateway.deleteMainGateway());
        await Gateway.assign(id);
        commit("ASSIGN_GATEWAY", id);
      } catch (error) {
        console.error(error.response.data.data);
        throw error;
      }
    },
    async deleteGateway({ commit }, id) {
      try {
        await Gateway.deletion(id);
        commit("DELETE_GATEWAY", id);
      } catch (error) {
        console.error(error.response.data.error);
        throw error;
      }
    },
  },
  mutations: {
    SET_GREEN_GATEWAYS(state, data) {
      data.forEach((obj) => {
        const collator = new Intl.Collator(undefined, {
          numeric: true,
          sensitivity: "base",
        });
        const sortedMeters = obj.meters.sort((a, b) =>
          collator.compare(a.name, b.name)
        );
        obj.meters = sortedMeters;
      });
      state.greenGateways = data;
      localStorage.setItem("greenGateway", JSON.stringify(data));
    },
    DELETE_GREEN_GATEWAY(state) {
      state.greenGateways = [];
      localStorage.removeItem("greenGateway");
    },
    ADD_METERS(state, meters) {
      const gateway = state.greenGateways[0];
      gateway.meters = state.greenGateways[0].meters.concat(meters);
      state.greenGateways = [gateway];
      localStorage.setItem("greenGateway", JSON.stringify([gateway]));
    },
    EDIT_METER(state, data) {
      const gateway = state.greenGateways[0];
      gateway.meters.find((meter) => {
        if (meter.id === data.id) {
          meter.name = data.name;
          meter.fare = data.fare;
          meter.capacity = data.capacity;
        }
      });
      state.greenGateways = [gateway];
      localStorage.setItem("greenGateway", JSON.stringify([gateway]));
    },
    DELETE_METER(state, meter) {
      const gateway = state.greenGateways[0];
      const filteredMeters = gateway.meters.filter((m) => m.id !== meter);
      gateway.meters = filteredMeters;
      localStorage.setItem("greenGateway", JSON.stringify([gateway]));
      state.greenGateways = [gateway];
    },
    SET_GATEWAYS(state, gateways) {
      localStorage.setItem("gateways", JSON.stringify(gateways));
      state.gateways = JSON.parse(localStorage.getItem("gateways"));
      state.gateways.sort((a, b) => b.main - a.main);
      const foundMainGateway = [...state.gateways].find(gateway => gateway.main === true)
      if(!foundMainGateway) return
      const { id } = foundMainGateway
      state.mainGateway = id
    },
    ADD_GATEWAY(state, data) {
      state.gateways.push(data);
      localStorage.setItem("gateways", JSON.stringify(state.gateways));
    },
    ASSIGN_GATEWAY(state, id) {
      state.gateways.forEach((gateway) => {
        gateway.main = gateway.id === id;
      });
      state.gateways.sort((a, b) => b.main - a.main);
      localStorage.setItem("gateways", JSON.stringify(state.gateways));
    },
    DELETE_GATEWAY(state, id) {
      state.gateways = state.gateways.filter((gateway) => gateway.id !== id);
      localStorage.setItem("gateways", JSON.stringify(state.gateways));
    },
  },
};
