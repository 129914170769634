<template>
  <div>
    <v-card
      class="notification-card"
      elevation="2"
      v-if="showNotification"
    >
      <v-card-title class="d-flex justify-space-between align-center">
        Complete profile
        <v-btn icon text @click="showNotification = false">
            <v-icon>
              mdi-close
            </v-icon>
        </v-btn>
      </v-card-title>      
      <v-card-text>
        <v-progress-linear class="mb-5" :value="progress" color="primary" height="20" rounded dark>
          <template v-slot:default="{ value }">
            <div class="text-center">{{ Math.round(value) }}%</div>
          </template>
        </v-progress-linear>
        <div v-if="!isThereMainGateway">
          <v-divider/>
          <p class="mt-2">Set main gateway</p>
          <v-divider/>
          <v-btn block color="secondary" class="mt-3" @click="openGatewaysDialog = true">
            Assign gateway as main
          </v-btn>
          <v-divider class="mt-2"/>
        </div>
        <div v-if="terminationDate == ''">
          <v-divider/>
          <p class="mt-2">Set termination date</p>
        </div>
        <div v-if="cost == 0">
          <v-divider/>
          <p class="mt-2">Set average kWh cost</p>
        </div>
        <div v-if="monthlyCost == 0">
          <v-divider/>
          <p class="mt-2">Set monthly cost</p>
        </div>
        <div v-if="!isInfoComplete()">
          <v-divider/>
          <v-btn block color="primary" class="mt-3" @click="$router.push({ name: 'ProjectInfo' }).catch(()=>{})"">
            Complete Information
          </v-btn>  
        </div>
        <div v-if="isInfoComplete()">
          <p>Information complete!</p>
        </div>
      </v-card-text>
    </v-card>
    <Gateways :open="openGatewaysDialog" @close="openGatewaysDialog = false"/>
  </div>  
</template>
  
  
  <script>
  import { mapState } from 'vuex';
  import Gateways from '../control/dialogs/Gateways.vue';
  export default {
    name: 'Notification',
    components: {
      Gateways,
    },
    data() {
      return {
        progress: 0,
        showNotification: true,
        isThereMainGateway: false,
        openGatewaysDialog: false,
        parameters: [
          'cost',
          'terminationDate',
          'monthlyCost',
          'gateways'
        ]
      };
    },
    computed: {
      ...mapState({
        isUserLoggedIn: (state) => state.authentication.status.loggedIn,
        cost: (state) => state.nodes.cost,
        terminationDate: (state) => state.nodes.terminationDate,
        monthlyCost: (state) => state.nodes.monthlyCost,
        gateways: (state) => state.gateways.gateways,
        isUserLoggedIn: (state) => state.authentication.status.loggedIn,
      })
    },
    methods: {
        isInfoComplete(){
          this.setProgress()
          if(this.progress >= 100) return true
          return false
        },
        setProgress(){
          let counter = 0
          const GATEWAYS = 'gateways'
          for (let index = 0; index < this.parameters.length; index++) {
            const parameter = this.parameters[index];
            if(parameter === GATEWAYS){
              this.isThereMainGateway = this[parameter].some(gateway => gateway.main === true)
              if(this.isThereMainGateway) counter++;
            } else {
              if(this[parameter]) counter++;
            }
          }
          this.progress = counter * 100 / this.parameters.length
          this.showNotification = this.progress < 100
        },
    },
  };
  </script>
  
  <style scoped>
  .notification-card {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 300px;
    z-index: 1000;
  }
  </style>