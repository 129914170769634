/* import config from 'config'; */
import axios from 'axios';
import firebase from 'firebase/app';
import { authHeader } from './helper';
import 'firebase/auth';
import { firebaseApp } from '../plugins/firebase';

/** This file handle all the requests related to user * */
export const user_service = {
  login,
  logout,
  signup,
};

const URL = process.env.VUE_APP_GENERAL_API_ENDPOINT;

function login(user, password, specific_service = null) {
  if (specific_service) {
    const request_options = {
      method: 'post',
      url: (`${URL}auth`),
      headers: authHeader(),
      data: JSON.stringify({ user, password }),
    };

    return axios(request_options)
      .then(handleResponse)
      .then((user) => {
        // login successful if there's a jwt token in the response
        if (user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('user', JSON.stringify(user));
        }
        return user;
      });
  }

  // else we will use firebase to authenticate an user (This is the main approach for auth)
  return firebase.auth(firebaseApp)
    .signInWithEmailAndPassword(user, password) // user here it's equal to email
    .then(
      async (user_credential) => {
        const { user } = user_credential;
        const token = await firebase.auth(firebaseApp).currentUser.getIdToken();
        // localStorage.setItem('user', JSON.stringify(user));
        const _user = JSON.parse(localStorage.getItem('user')); // weird but needed
        _user.token = token;
        return _user;
      },
      (onerror) => Promise.reject(onerror),
    );
}

function signup(email, password) {
  const request_options = {
    method: 'post',
    url: (`${URL}signup`),
    headers: authHeader(),
    data: JSON.stringify({ email, password }),
  };

  return axios(request_options)
    .then(handleResponse)
    .then((response) => {
      // sign up successful
      console.info('Sign up was successful');

      return response;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  firebase.auth(firebaseApp).signOut();
}

function handleResponse(response) {
  const OK_STATUS = 200;

  if (response.status != OK_STATUS) {
    // simply return the whole response object
    return Promise.reject(response);
  }

  // if we dont't receive an error status code
  response.data.token = response.headers['x-auth-token'];
  return response.data; // return "data" that is meant to be a json object. with the included token
}
