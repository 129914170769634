import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyDvWyUegAYW0yC5uykoW5whq4ZIGNNyaf0',
  authDomain: 'angfiretest-ec6ba.firebaseapp.com',
  databaseURL: 'https://angfiretest-ec6ba.firebaseio.com',
  projectId: 'angfiretest-ec6ba',
  storageBucket: 'angfiretest-ec6ba.appspot.com',
  messagingSenderId: '1056825165462',
  appId: '1:1056825165462:web:9b48e09f31d76accaea4b7',
  measurementId: 'G-11FWQH9X4G',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();
const db = firebase.firestore();
const auth = firebase.auth();
const { firestore } = firebase;
const storage = firebase.storage();

export {
  firebaseApp,
  db,
  firestore,
  auth,
  storage,
  analytics,
};

// export default db
