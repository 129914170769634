import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "../plugins/firebase";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/"
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home"),
    meta: {
      public: false, title: "Home"
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../components/Login"),
    meta: {
      public: true, title: "Log In"
    }
  },
  {
    path: "/control",
    name: "control",
    component: () => import("../views/Control"),
    meta: {
      public: false, title: "Control Center"
    }
  },
  // {
  //   path: "/schedules/",
  //   name: "schedule",
  //   component: () => import("@/views/Schedule"),
  //   meta: {
  //     public: false, title: "Schedules"
  //   }
  // },
  // {
  //   path: "/logger",
  //   name: "Logger",
  //   component: () => import("@/views/Logger"),
  //   meta: {
  //     public: false, title: "Log Center"
  //   }
  // },
  // {
  //   path: "/project",
  //   name: "ProjectInfo",
  //   component: () => import("@/views/ProjectInfo"),
  //   meta: {
  //     public: false, title: "Project Info"
  //   }
  // },
  // {
  //   path: "/layout-modify",
  //   name: "Lyt-Modify",
  //   component: () => import("@/views/Lyt-Modify"),
  //   meta: {
  //     public: false, title: "Layouts"
  //   }
  // },
  // {
  //   path: "/layout-meter",
  //   name: "Lyt-Meter",
  //   component: () => import("@/views/Lyt-Meter"),
  //   meta: {
  //     public: false, title: "Metering Layout"
  //   }
  // },
  // {
  //   path: "/layout-one-line",
  //   name: "Lyt-OneLine",
  //   component: () => import("@/views/Lyt-OneLine"),
  //   meta: {
  //     public: false, title: "One Line Diagram Layout"
  //   }
  // },
  // {
  //   path: "/layout-control",
  //   name: "Lyt-Control",
  //   component: () => import("@/views/Lyt-Control"),
  //   meta: {
  //     public: false, title: "Layout Control"
  //   }
  // },
  {
    path: "/meters",
    component: () => import("@/views/MeterPage"),
    children: [
      {
        path: "/",
        name: "MeterMenu",
        component: () => import("@/components/meter/MeterMenu"),
        meta: {
          public: false, title: "Meter Menu"
        }
      },
      {
        path: ":id",
        name: "MeterV2",
        component: () => import("@/components/meter/MeterV2"),
        meta: {
          public: false, title: "Meter View"
        }
      },
    ]
  },
  // {
  //   path: "/meter/:id",
  //   name: "Meter",
  //   component: () => import("@/views/Meter"),
  //   meta: {
  //     public: false, title: "Meter View"
  //   }
  // },
  // {
  //   path: "/groups",
  //   component: () => import("@/views/GroupPage"),
  //   children: [
  //     {
  //       path: "",
  //       name: "GroupMenu",
  //       component: () => import("@/components/group/GroupMenu"),
  //       meta: {
  //         public: false, title: "Group Menu"
  //       }
  //     },
  //     {
  //       path: "/groups/:id",
  //       name: "Group",
  //       component: () => import("@/components/group/GroupSelected"),
  //       meta: {
  //         public: false, title: "Group View"
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: "/downtime",
  //   name: "Downtime",
  //   component: () => import("@/views/Downtime"),
  //   meta: {
  //     public: false, title: "Downtime"
  //   }
  // }
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  store.dispatch("alert/clear", null, { root: true });

  const { currentUser } = auth
  const isPublic = to.meta.public

  if(!isPublic && !currentUser){
    return next('/login')
  }

  if(isPublic && currentUser){
    return next('/')
  }

  next();
});

export default router;
