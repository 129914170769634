<template>
    <v-dialog
      v-model="updateExists"
      max-width="330"
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-h6">
          <v-row>
            <v-col> Update Available </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          A new version of the app is available. Please update to the latest
          version. 
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('updateNow')"> Update </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: {
      updateExists: {
        type: Boolean,
        required: true,
      },
    },
  };
  </script>
  