export const WATTS = 'watts';
export const AMPS = 'amps';
export const VOLTS = 'volts';
export const KWH = 'kwht';
export const PF = 'pf';
export const THD = 'THD';
export const VAR = 'var';

export const COLUMS = 'bar';
export const LINES = 'line';

export const LINE1 = '0';
export const LINE2 = '1';
export const LINE3 = '2';

export const AVG = 'avg';
export const SUM = 'sum';
export const MAX = 'max';
export const MIN = 'min';

export const WIDGET = 'widget';
export const CHART = 'chart';
