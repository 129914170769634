import axios from 'axios';
import { handle_response } from './helper';

export const zones_service = {
  get_zones,
  create_zone,
  update_zone,
  delete_zone,
};

const URL = process.env.VUE_APP_GENERAL_API_ENDPOINT;

function get_zones(options) {
  const request_options = {
    method: 'get',
    url: (`${URL}zones`),
    params: options,
  };

  return axios(request_options)
    .then((value) => handle_response(value));
}

function create_zone(payload) {
  console.log(payload);
  const request_options = {
    method: 'post',
    url: (`${URL}zones`),
    data: payload,
  };

  return axios(request_options)
    .then((value) => handle_response(value));
}

function update_zone(payload, zone_id) {
  const request_options = {
    method: 'put',
    url: (`${URL}zones` + `/${zone_id}`),
    data: payload,
  };

  return axios(request_options)
    .then((value) => handle_response(value));
}

function delete_zone(zone_id) {
  const request_options = {
    method: 'delete',
    url: (`${URL}zones` + `/${zone_id}`),
  };

  return axios(request_options)
    .then((value) => handle_response(value));
}
