import { instructions_service } from '@/_services';

export const instructions = {
  namespaced: true,
  state: {
    instructions: [],
  },
  getters: {
    get_instruction_by_id(state, timestamp_id) {
      return state.instructions.find((instruction) => instruction.timestamp_server === timestamp_id);
    },
    get_instructions(state) {
      return state.instructions;
    },
  },
  actions: {
    // this action calls the respective service which will do the http request for us
    request_instructions({ commit, dispatch }, query_params = null) {
      instructions_service.get_instructions(query_params)
        .then((instructions) => {
          console.log(instructions);
          commit('SET_INSTRUCTIONS', instructions.Items);
        })
        .catch((err) => console.log(err));
    },

  },
  mutations: {
    SET_INSTRUCTIONS(state, instructions) {
      state.instructions = instructions;
    },
  },

};
