export function authHeader(specific_service = false) {
  let user;
  try {
    user = JSON.parse(localStorage.getItem('user'));
  } catch (e) {
    return { 'Content-Type': 'application/json' };
  }

  if (user && user.token) {
    return {
      'Content-Type': 'application/json',
      Authorization: user.token,
      'specific-service': (specific_service) || false,
    };
  }
  return { 'Content-Type': 'application/json' };
}

export function getUser() {
  return JSON.parse(localStorage.getItem('user'));
}

export function handle_response(response) {
  const OK_STATUS = 200;

  if (response.status != OK_STATUS) { return Promise.reject(response); } // simply return the whole response object

  return response.data;
}
