import { user_service } from '@/_services';

export const signup = {
  namespaced: true,
  state: {
    signing_up: false,
    signed_up: false,
  },
  getters: {
    is_signing_up(state) {
      return state.signing_up;
    },
  },
  actions: {
    sign_up({ commit }, payload) {
      const { email, password } = payload;
      commit('SET_SIGNING_UP', true);
      user_service.signup(email, password)
        .then((value) => {
          console.info(value);
          commit('SET_SIGNED_UP', true);
        })
        .catch((e) => { console.error(e); })
        .finally(() => commit('SET_SIGNING_UP', false));
    },
  },
  mutations: {
    SET_SIGNING_UP(state, payload) {
      state.signing_up = payload;
    },
    SET_SIGNED_UP(state, payload) {
      state.signed_up = payload;
    },
  },
};
