import { apiWithProject } from './greenetworkAPI';

const END_POINT = '/devices';

const get = () => apiWithProject.get(`${END_POINT}`);

const getNode = (node) => apiWithProject.get(`${END_POINT}/${node}`);

const post = (node, params) => apiWithProject.post(`${END_POINT}/${node}`, {
  function: params.function,
  class: params.class,
  displayName: params.displayName,
});

const deletion = (node) => apiWithProject.delete(`${END_POINT}/${node}`);

const Device = { get, getNode, post, deletion };

export default Device;
